export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigungs-E-Mail erneut senden"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisieren"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto löschen"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten-Export anfragen"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
}