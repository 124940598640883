export default {
  "Cycling (Sport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo (Deportivo)"])}
  },
  "Cycling (Transport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo (Transporte)"])}
  },
  "Cycling (Virtual)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo (Virtual)"])}
  },
  "Hiking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendeirismo"])}
  },
  "Mountain Biking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo por Montaña"])}
  },
  "Mountain Biking (Electric)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo por Montaña (Eléctrica)"])}
  },
  "Mountaineering": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montañismo"])}
  },
  "Rowing": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remo"])}
  },
  "Running": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correr"])}
  },
  "Skiing (Alpine)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí (Alpino)"])}
  },
  "Skiing (Cross Country)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí (de travesía)"])}
  },
  "Snowshoes": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raquetas de neve"])}
  },
  "Trail": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trail"])}
  },
  "Walking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camiñar"])}
  },
  "Paragliding": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paracaidismo"])}
  },
  "Open Water Swimming": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natación Auga Aberta"])}
  }
}