export default {
  "Cycling (Sport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolarstwo (Sport)"])}
  },
  "Cycling (Transport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolarstwo (Transport)"])}
  },
  "Cycling (Virtual)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolarstwo (Wirtualne)"])}
  },
  "Hiking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piesza wycieczka"])}
  },
  "Mountain Biking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolarstwo górskie"])}
  },
  "Mountain Biking (Electric)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolarstwo górskie (Elektryczne)"])}
  },
  "Mountaineering": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpinizm"])}
  },
  "Rowing": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wioślarstwo"])}
  },
  "Running": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieg"])}
  },
  "Skiing (Alpine)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narciarstwo alpejskie"])}
  },
  "Skiing (Cross Country)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narciarstwo biegowe"])}
  },
  "Snowshoes": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakiety śnieżne"])}
  },
  "Trail": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szlak"])}
  },
  "Walking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chód"])}
  },
  "Paragliding": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paralotniarstwo"])}
  },
  "Open Water Swimming": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pływanie na wodach otwartych"])}
  }
}