export default {
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notatki"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak notatek"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezchmurny dzień"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezchmurna noc"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pochmurnie"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mgła"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trochę pochmurny dzień"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trochę pochmurna noc"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deszcz"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deszcz ze śniegiem"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śnieg"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wiatr"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wilgotność"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatura"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wiatr"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsch."])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsch.-Płn.-Wsch."])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płn.-Zach."])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płd."])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płd.-Wsch."])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płd.-Płd.-Wsch."])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsch.-Płd.-Wsch."])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płn."])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płn.-Wsch."])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płn.-Płn.-Wsch."])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płn.-Płn.-Zach."])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płd.-Płd.-Zach."])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płd.-Zach."])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zach."])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zach.-Płn.-Zach."])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zach.-Płd.-Zach."])}
    }
  },
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj trening"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analiza"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wzniesienie"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zejście"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas trwania musi być większy niż 0 sekund"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma następnego treningu"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["rekord"]), _normalize(["rekordy"])])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tytuł"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prędkość"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wróć do treningu"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["średnia prędkość"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śr. prędkość"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtry wyświetlania"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dystans"])},
  "DOWNLOAD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz trening"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["czas trwania"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj trening"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wysokość"])},
  "EXIT_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz pełny ekran"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plik .gpx"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukryj filtry"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obie wysokości muszą być podane i muszą być większe niż lub równe zeru."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dystans musi być większy niż 0"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie treningi"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczytaj więcej treningów"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. wysokość"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. plików"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. wielkość"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. prędkość"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. wysokość"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następny segment"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następny trening"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dane z pliku gpx, bez żadnego czyszczenia"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie podano pliku"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie ma folderu w środku"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak mapy"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma poprzedniego segmentu"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma poprzedniego treningu"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak rekordów."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak treningów."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przerwy"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzedni segment"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzedni trening"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["całkowity czas trwania"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij jakiś!"])},
  "VIEW_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz pełny ekran"])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śr. prędkość"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najdłuższy dystans"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najwyższe wzniesienie"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najdłuższy czas trwania"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. prędkość"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozostałych znaków"])},
  "RESET_ZOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset zbliżenia"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segment"]), _normalize(["segmenty"])])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sporty"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start i koniec"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zacznij oś wysokości od zera"])},
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez pliku .gpx"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z plikiem .gpx"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["trening"]), _normalize(["treningi"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data treningu"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno usunąć ten trening?"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lub .zip zawierający pliki .gpx"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plik .zip"])},
  "DELETE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń ten trening"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koniec"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma następnego segmentu"])}
}