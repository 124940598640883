<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 463.507 463.507"
    style="enable-background: new 0 0 463.507 463.507"
    xml:space="preserve"
  >
    <desc id="hikingDescription">silhouette of a person hiking</desc>
    <g>
      <path
        d="M246.413,78.492c21.688,0,39.255-17.573,39.255-39.251c0-21.67-17.567-39.24-39.255-39.24
		c-21.652,0-39.242,17.57-39.242,39.24C207.171,60.919,224.761,78.492,246.413,78.492z"
      />
      <path
        d="M386.604,202.858c0-11.185-9.066-20.251-20.253-20.251h-68.479l-38.62-54.832l0.127-0.933
		c1.378-10.474-1.474-21.067-7.911-29.444c-6.441-8.378-15.932-13.852-26.408-15.23c-11.596-1.511-22.592,2.224-30.852,9.225V45.779
		c0-7.847-6.362-14.217-14.225-14.217H140.59c-7.867,0-14.225,6.37-14.225,14.217v168.953c0,20.68,15.821,37.476,35.979,39.446
		l-3.043,7.073l-23.859,90.136l-53.73,72.188c-8.006,10.768-5.794,25.987,4.984,34.001c4.348,3.245,9.443,4.811,14.491,4.811
		c7.422,0,14.729-3.385,19.511-9.795l56.529-75.945c1.851-2.484,3.213-5.299,4.003-8.289l16.266-61.414l44.521,40.877l-6.076,88.603
		c-0.917,13.393,9.177,24.99,22.58,25.908c0.552,0.04,1.124,0.056,1.691,0.056c12.66,0,23.339-9.819,24.208-22.642l6.882-100.264
		c0.508-7.364-2.371-14.572-7.815-19.564l-45.994-42.219l13.992-90.613l19.331,27.435c3.801,5.387,9.972,8.592,16.552,8.592h70.882
		l1.339,232.294c0,4.478,3.626,8.101,8.101,8.101c4.479,0,8.101-3.624,8.101-8.101l-1.339-234.036
		C381.588,218.245,386.604,211.15,386.604,202.858z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Hiking',
  }
</script>
