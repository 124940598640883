export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["om"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreftelse"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["dag"]), _normalize(["dager"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vist"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dokumentasjon"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjult"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjem"])},
  "SELECTS": {
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorter etter"])}
    }
  }
}