<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -51 512 512">
    <g id="error">
      <path
        class="error-page-img"
        d="M 0 0 C 0 11.300781 0 399.777344 0 410 L 512 410 C 512 402.324219 512 2.425781 512 0 Z M 370 71 L 370 30 L 411 30 L 411 71 Z M 30 30 L 340 30 L 340 71 L 30 71 Z M 482 380 L 30 380 L 30 101 L 482 101 Z M 441 71 L 441 30 L 482 30 L 482 71 Z M 441 71 "
      />
      <path
        class="error-page-img"
        d="M 325.519531 297.070312 C 294.328125 265.878906 294.328125 215.125 325.519531 183.929688 L 304.304688 162.71875 C 261.417969 205.605469 261.417969 275.390625 304.304688 318.28125 Z M 325.519531 297.070312 "
      />
      <path
        class="error-page-img"
        d="M 197.089844 180 L 237.089844 180 L 237.089844 220 L 197.089844 220 Z M 197.089844 180 "
      />
      <path
        class="error-page-img"
        d="M 197.089844 261 L 237.089844 261 L 237.089844 301 L 197.089844 301 Z M 197.089844 261 "
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'ErrorImg',
  }
</script>
