export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new OAuth2 application"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application description"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue at"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application name"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URL"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants write access to application configuration."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants read access to <code>auth</code> endpoints."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants write access to <code>auth</code> endpoints."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants read access to <code>users</code> endpoints."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants write access to <code>users</code> endpoints."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants read access to <code>workouts</code> endpoints."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants write access to <code>workouts</code> endpoints."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application URL"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth2 applications"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application created successfully. Make sure to copy the secret now, it won't show up again."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this app?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The application ", _interpolate(_list(0)), " is requesting:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Authorize ", _interpolate(_list(0)), " to use your account?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy to the clipboard"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete application"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an application"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application not found!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no applications"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no description"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke all tokens"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to revoke all tokens?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All existing associated tokens have been revoked."])}
}