export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No has recibido instrucciones?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprueba el correo. Se ha enviado un nuevo email de confirmación a la dirección proporcionada."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes una cuenta?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que quieres eliminar tu cuenta? Se borrarán todos los datos, la decisión no es reversible."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe una dirección válida de correo."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe una contraseña"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar datos"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar archivo"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creando enlace..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes solicitar un archivo cada 24 horas"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha fallado (solicita una nueva exportación)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en progreso..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por nombre de usuario"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ocultar contraseña"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave secreta no válida, solicita un nuevo restablecimiento de contraseña."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero eliminar mi cuenta"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La política de privacidad ha sido actualizada, ", _interpolate(_list(0)), " antes de continuar."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar sesión"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay usuarios."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has olvidado la contraseña?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requieren 8 caracteres como mínimo."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la contraseña"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprueba tu email. Si la dirección está en nuestra base de datos recibirás un email con un enlace para restablecer tu contraseña."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buena"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fortaleza de la contraseña"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuerte"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa alguna mayúscula, pero no todas."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade más palabras poco comunes."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita los años asociados a ti."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa alguna mayúscula más que en la primera."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita fechas y años que se puedan asociar a ti."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita substituciones evidentes de letras como ", "@", " por la a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa patrones de teclado más largos y cambia de dirección de escritura varias veces."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes crear contraseñas fuertes sin usar símbolos, números o letras mayúsculas."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si utilizas esta contraseña en otros lugares, deberías cambiarla."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita años recientes."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita palabras y caracteres repetidos."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita palabras del revés o palabras comunes."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita secuencias comunes de caracteres."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa varias palabras, pero evita frases comunes."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["débil"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se ha actualizado tu contraseña. Pulsa ", _interpolate(_list(0)), " para iniciar sesión."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición de la cuenta"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de ascensión (récords, total)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al perfil"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografía"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de la fecha"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias de los deportes"])},
    "ELEVATION_CHART_START": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La elevación del gráfico empieza en"])},
      "MIN_ALT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitud mínima"])},
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cero"])}
    },
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Por favor, ", _interpolate(_list(0)), " para cambiar su dirección de correo electrónico de nuevo o póngase en contacto con el administrador"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer día de la semana"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografía"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar la foto"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la foto"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar la foto"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acción"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["color"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desactivada por admin"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etiqueta"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límite para detectar detención"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias de los deportes"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha actualizado correctamente tu cuenta. Comprueba tu email para confirmar tu nueva dirección de correo electrónico."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha creado correctamente tu cuenta."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado un enlace en un email a tu correo electrónico para activar la cuenta."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta ha sido actualizada correctamente."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aplicaciones"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fotografía"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preferencias"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perfil"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deportes"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona horaria"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema Imperial (ft, mi, mph, ºF)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de distancia"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema Métrico (m, km, m/s, ºC)"])}
    },
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrategia de velocidad máxima"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bruto"])},
      "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se filtra, excluye los valores extremos (que pueden ser errores del GPS) al calcular la velocidad máxima."])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["He leído y aceptado la ", _interpolate(_list(0)), "."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no se admiten nuevas cuentas."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar email de confirmación de cuenta"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisar"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar contraseña"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta cuenta de usuario no está activa."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requieren entre 3 y 30 caracteres, sólo se permiten alfanuméricos y guión bajo \"_\"."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foto del usuario"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Has aceptado la ", _interpolate(_list(0)), "."])},
  "ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmacion la cuenta"])},
  "LOGOUT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que deseas cerrar la sesión?"])},
  "EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización el correo electrónico"])}
}