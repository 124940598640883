export default {
  "CONTENT": {
    "ACCOUNT_DELETION": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt uw account op elk moment opzeggen door naar volgend adres te gaan, en na inloggen op de knop \"Verwijder mijn account\" te klikken in uw instellingen."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verwijderen"])}
    },
    "CHANGES_TO_OUR_PRIVACY_POLICY": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventuele aanpassingen aan het privacybeleid zullen op deze pagina worden weergegeven.\n\nDit document valt onder volgende licentie [CC-BY-SA](https://creativecommons.org/licenses/by-sa/4.0/). Een aanpassing van dit origineel privacybeleid [Discourse](https://github.com/discourse/discourse)."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassingen aan ons Privacybeleid"])}
    },
    "DATA_COLLECTED": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgende informatie wordt verzameld:\n- Account informatie (gebruikersnaam, email en wachtwoord). U kan ook bijkomende informatie opgeven zoals: naam, voornaam, geboortedatum, locatie, biografie en een profiel-afbeelding.\n- [GPX](https://en.wikipedia.org/wiki/GPS_Exchange_Format) bestanden. Deze bestanden bevatten gegevens over uw activiteiten (coördinaten, datums, afstand, duur, maximum en minimum snelheden, hoogtegegevens, hartslag, ...). Als u bepaalde gegevens hiervan niet wil delen, verwijder deze dan uit het bestand alvorens deze te uploaden.\n- Sportgerelateerde gegevens (soort sport, titel, datum, duur, afstand, geklommen afstand, gedaalde afstand, notities).\n- Technische informatie (de browsernaam en naam van het besturingssysteem)."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke informatie wordt er door ons verzameld?"])}
    },
    "INFORMATION_DISCLOSURE": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke informatie wordt niet verkocht, geruild of op enige manier doorgegeven aan derden/andere instanties.\n\nDe uitzondering hierop zijn derden die vertrouwd worden en hebben ingestemd om uw gegevens niet te delen. Dit zijn bijvoorbeeld website administrators en vertrouwde applicaties.\n\nWanneer je een app van derden linkt aan je gebruikersaccount, kan het naargelang de toegestane rechten toegang krijgen tot je profiel informatie en activiteiten. Apps hebben nooit toegang tot uw wachtwoord.\n\nMogelijk kan er informatie worden gedeeld wanneer dit nodig is om aan de wetgeving te voldoen, wanneer de regels van ons beleid dienen afgedwongen te worden, of om onze of anderen hun rechten, eigendom of veiligheid te beschermen."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt er informatie met derden gedeeld?"])}
    },
    "INFORMATION_PROTECTION": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er worden verschillende maatregelen gebruikt om de veiligheid van uw persoonlijke informatie te beschermen. Zowel tijdens het ingegeven, uploaden en bekijken van uw informatie."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe beschermen we uw informatie?"])}
    },
    "INFORMATION_USAGE": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De informatie die we verzamelen is nodig voor de basis functionaliteit van **FitTrackee**:\n- GPX worden gebruikt om activiteiten aan te maken, routes te tonen op de map (door [OpenStreetMap](https://www.openstreetmap.org), grafieken te tonen, thumbnails voor de route aan te maken, berekenen van records en weerdata op te vragen (indien geconfigureerd).\n- Profiel informatie en activiteiten zijn niet publiek zichtbaar. Een gebruiker kan enkel zijn eigen activiteiten bekijken.\n- Het opgegeven email adres kan worden gebruikt om informatie met u te delen, of om wijzigingen aan uw account te melden."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarvoor wordt de opgegeven informatie gebruikt?"])}
    },
    "SITE_USAGE_BY_CHILDREN": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als de server zich in de EU of EEA bevindt: Deze site en zijn services zijn gericht op personen van minstens 16 jaar oud. Als u onder de 16 jaar bent, volgens naleving van de [GDPR](https://en.wikipedia.org/wiki/General_Data_Protection_Regulation) (General Data Protection Regulation), gelieve deze site niet te gebruiken.\n\nAls de server zich in de USA bevindt: Deze site en zijn services zijn gericht op personen van minstens 13 jaar oud. Als u onder de 13 jaar bent, volgens naleving van de [COPPA](https://en.wikipedia.org/wiki/Children%27s_Online_Privacy_Protection_Act) (Children's Online Privacy Protection Act), gelieve deze site niet te gebruiken.\n\nWetgeving kan verschillen als de server zich op een andere plaats bevindt."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik door minderjarigen"])}
    },
    "YOUR_CONSENT": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door deze site te gebruiken, gaat u akkoord met ons privacybeleid."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw toestemming"])}
    }
  },
  "LAST_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste aanpassing"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacybeleid"])}
}