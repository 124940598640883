export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training toevoegen"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyse"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geklommen"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemiddelde snelheid"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gem. snelheid"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar training"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedaald"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toon filters"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afstand"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duur"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training aanpassen"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoogte"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einde"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".gpx bestand"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verberg filters"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide waarden moeten opgegeven worden en dienen groter dan of gelijk aan 0 te zijn."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De afstand moet groter zijn dan 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De duur moet langer zijn dan 0 seconden"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste trainingen"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon meer trainingen"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. hoogte"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. aantal bestanden"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. grootte"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. snelheid"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. hoogte"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen volgend segment"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende training"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notities"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data vanuit gpx, zonder op te schonen"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestand opgegeven"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen map aanwezig"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kaart"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen volgend segment"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen volgende training"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen notities"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vorig segment"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vorige training"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen records."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen trainingen."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pauzes"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorig segment"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige training"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["record"]), _normalize(["records"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem. snelheid"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langste afstand"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogst geklommen"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langste duur"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. snelheid"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resterende karakters"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segment"]), _normalize(["segmenten"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["snelheid"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sporten"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start en aankomst"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet hoogte-as vast op 0"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titel"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tem."])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totale duur"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén uploaden!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heldere dag"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heldere nacht"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bewolkt"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mist"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedeeltelijk bewolkte dag"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedeeltelijk bewolkte nacht"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regen"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natte sneeuw"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sneeuw"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wind"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vochtigheid"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatuur"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wind"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONO"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OZO"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNO"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNW"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NW"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZO"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZZO"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZZW"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZW"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNW"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WZW"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zonder .gpx bestand"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met .gpx bestand"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["workout"]), _normalize(["workouts"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum workout"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze workout wilt verwijderen?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".zip bestand"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of .zip archief met .gpx bestanden"])},
  "EXIT_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlaat volledig scherm"])},
  "DELETE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder de workout"])},
  "RESET_ZOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset zoomniveau"])},
  "DOWNLOAD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download de workout"])},
  "VIEW_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volledig scherm"])}
}