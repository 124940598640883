export default {
  "ERROR": {
    "<time> is missing in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elément <time> manquant dans le fichier .gpx."])},
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur réseau."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur. Veuillez réessayer ou contacter l'administrateur."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un fichier de l'archive zip dépasse la taille maximale, veuillez vérifier l'archive."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une demande d'export terminée existe déjà."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel : une adresse électronique valide doit être fournie."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'analyse du fichier."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du traitement du fichier gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la récupération de la configuration."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'enregistrement de la séance."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la mise à jour de la configuration"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur, veuillez réessayer ou contacter l'administrateur."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur, les inscriptions sont désactivées."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de fichier non autorisée."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille du fichier est supérieure à la limite autorisée."])},
    "gpx file is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier .gpx est invalide."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants invalides."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données fournies incorrectes."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton de connexion invalide, merci de vous reconnecter."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton de connexion invalide, veuillez demander un nouveau jeton."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nouvelle adresse électronique doit être différente de l'adresse actuelle"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de fichier fourni."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de fichier sélectionné."])},
    "no tracks in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de trace (<trk>) dans le fichier .gpx."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une demande d'exportation de données existe déjà."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe : les mots de passe saisis sont différents."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de fournir un jeton de connexion valide."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature expirée. Merci de vous reconnecter."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, ce nom d'utilisateur est déjà utilisé."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sport n'existe pas."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription validée."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de fichiers de l'archive dépasse la limite."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur n'existe pas."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une adresse électronique doit être fournie pour le contact de l'administrateur"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer votre compte, aucun autre utilisateur n'a des droits d'administration."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les permissions nécessaires."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suivant"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["précédent"])}
  }
}