<template>
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 494.49 494.49"
    style="enable-background: new 0 0 494.49 494.49"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <desc id="walkingDescription">silhouette of a walking person</desc>
    <defs id="defs797" />
    <g id="g792">
      <path
        d="m 272.74,80.771 c 22.318,0 40.401,-18.08 40.401,-40.389 C 313.141,18.084 295.058,0 272.74,0 c -22.281,0 -40.378,18.084 -40.378,40.383 0,22.308 18.096,40.388 40.378,40.388 z"
        id="path788"
      />
      <path
        d="m 328.5854,174.48445 -40.64148,10.93729 4.76008,-51.90474 0.123,-0.944 c 1.384,-10.514 -1.475,-21.146 -7.94,-29.556 -6.461,-8.409 -16.007,-13.903 -26.52,-15.287 -10.926,-1.429 -22.619,3.12 -31.206,8.646 -1.441,0.928 -84.97,54.921 -84.97,54.921 -5.175,3.358 -8.542,8.877 -9.165,15.016 -0.634,6.13 1.574,12.222 5.976,16.541 l 58.982,58 -6.417,48.954 -25.88143,79.08977 -54.08338,43.67932 c -14.762199,11.1022 -24.410111,22.37008 -15.912276,36.07186 8.515526,13.68031 27.439076,15.75973 42.273746,4.63076 l 48.9456,-38.84296 c 12.09075,-7.84858 23.84126,-20.18502 28.3003,-35.27702 L 235.052,315.187 l 26.365,36.639 6.684,119.628 c 0.73,12.991 11.501,23.036 24.349,23.036 0.441,0 0.92,-0.016 1.379,-0.039 13.453,-0.748 23.745,-12.262 23,-25.713 l -7.083,-126.736 c -0.271,-4.643 -1.846,-9.116 -4.56,-12.887 l -32.24,-44.811 7.981,-57.1 c -15.00943,6.0905 -25.84439,5.48716 0,0 l 59.50354,-17.8263 c 10.75379,-3.22166 16.4326,-12.34116 13.52152,-25.36894 -2.44853,-10.95778 -14.74571,-13.15695 -25.36666,-9.52431 z M 204.606,190.357 185.58,171.64 209.056,156.434 Z"
        id="path790"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Walking',
  }
</script>
