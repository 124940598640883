export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier tipo de información adicional que pueda ser útil para tus usuarios. Puedes usar Markdown."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información detallada sobre la instancia"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar cuenta"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir/Retirar derechos de aministración, eliminar cuenta de usuario."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación"])},
  "APP_CONFIG": {
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto por email con la administración"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Núm. máximo de ficheros en el zip"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si 0, no hay límite para los registros."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Núm. máximo de usuarios activos"])},
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin email de contacto"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máx. de los ficheros subidos (en Mb)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la aplicación"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máx. del fichero zip (en Mb)"])}
  },
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a admin"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Seguro que quieres eliminar la cuenta ", _interpolate(_list(0)), "? Se borrarán todos los datos y no será reversible."])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Seguro que quieres restablecer la contraseña de ", _interpolate(_list(0)), "?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email actual"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar usuario"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El envío de email está desactivado."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar/desactivar deportes."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo email"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay texto"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha restablecido la contraseña."])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade tu propia política de privacidad o déjalo en blanco para usar una por defecto. Puedes usar Markdown."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente está desactivada la creación de cuentas."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente está abierta la creación de cuentas."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ya existe el entrenamiento"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar deportes"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar la configuración de la aplicación."])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar email"])},
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["usuario"]), _normalize(["usuarios"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estatus de admin"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data de registro"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo de cuenta"])},
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificador"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de entramientos"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otorgar permiso de admin"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar permiso de admin"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración - Usuarios"])}
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección de email ha sido actualizada."])}
}