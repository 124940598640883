export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non recibiches instrucións?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comproba o correo. Enviouse un correo de confirmación ao enderezo proporcionado."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xa tes unha conta?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes a certeza de querer eliminar a conta? Todos os datos serán borrados, e non poderás restablecelos."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasinal actual"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe un enderezo válido."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe un contrasinal"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar datos"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar arquivo"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creando ligazón..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podes solicitar un arquivo cada 24 horas"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fallou (solicita outra exportación)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en progreso..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por identificador"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agochar contrasinal"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token non válido, solicita un novo restablecemento de contrasinal."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quero eliminar a miña conta"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actualizouse a política de privacidade, podes ", _interpolate(_list(0)), " antes de continuar."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pechar sesión"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acceder"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo contrasinal"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai usuarias."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasinal"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueciches o contrasinal?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Require 8 caracteres como mínimo."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contrasinal"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comproba o correo electrónico. Se o enderezo está na base de datos recibirás un correo cunha ligazón de restablecemento."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boa"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forza do contrasinal"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forte"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclúe algunha letra maiúscula, non todas."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engade máis palabras pouco comúns."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita os anos que teñan relación contigo."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa máis maiúsculas que só na primeira letra."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita datas e anos que teñan relación contigo."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita substitucións de letras que sexan evidentes como ", "@", " polo a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa patróns longos no teclado e cambia de dirección de escritura varias veces."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podes crear contrasinais fortes sen usar símbolos, números ou letras maiúsculas."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usas este contrasinal noutros lugares entón deberías cambialo."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita anos recentes."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita palabras e caracteres repetidos."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita palabras comúns escritas ao revés."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita secuencias comúns de caracteres."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa varias palabras, pero evita frases comúns."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feble"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actualizouse o teu contrasinal. Preme en ", _interpolate(_list(0)), " para acceder."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición da conta"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de ascensión (gravacións, total)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver ao perfil"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nacemento"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato para as datas"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias dos deportes"])},
    "ELEVATION_CHART_START": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O gráfico de elevación comeza en"])},
      "MIN_ALT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude mínima"])},
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cero"])}
    },
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " para cambiar o enderezo de correo electrónico ou contacta coa administración"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro día da semana"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apelido"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luns"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografía"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición da foto"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar foto"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar foto"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar preferencias"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición do perfil"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de rexistro"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acción"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cor"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desactivado por admin"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etiqueta"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limiar para detectar pausa"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición das preferencias do deporte"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conta actualizouse correctamente. Comproba o teu correo para confirmar o novo enderezo de correo electrónico."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creouse correctamente a conta."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviouse un correo ao enderezo proporcionado cunha ligazón para activar a conta."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizouse correctamente a conta."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conta"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apps"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imaxe"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preferencias"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perfil"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deportes"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona horaria"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema Imperial (ft, mi, mph, ºF)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de distancia"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema Métrico (m, km, m/s, ºC)"])}
    },
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratexia para velocidade máx. GPX"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bruto"])},
      "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se filtras, excluirá os valores extremos (que poderían deberse a erros do GPS) ao calcular a velocidade máxima."])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lin e acepto a ", _interpolate(_list(0)), "."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear conta"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentámolo, pero non se permiten novas contas."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar o correo de confirmación de conta"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablece o contrasinal"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisar"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar contrasinal"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conta de usuaria está desactivada."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Require entre 3 e 30 caracteres, só se permiten alfanuméricos e o trazo baixo \"_\"."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foto da usuaria"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aceptaches a ", _interpolate(_list(0)), "."])},
  "ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación da conta"])},
  "EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar correo"])},
  "LOGOUT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes certeza de querer pechar a sesión?"])}
}