export default {
  "ABOUT": {
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljert info om instansen"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrasjon"])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vil du tilbakestille ", _interpolate(_list(0)), " passord?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåværende e-postadresse"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett bruker"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tekst innskrevet"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordet har blitt tilbakestilt."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passord"])},
  "SPORTS": {
    "TABLE": {
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilde"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett"])}
    }
  },
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["bruker"]), _normalize(["brukere"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administratorstatus"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registreringsdato"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontostatus"])}
      }
    }
  }
}