export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un workout"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analisi"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salita"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocità media"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vel. media"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ritorna al workout"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discesa"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostra filtri"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distanza"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durata"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica il workout"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elevazione"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fine"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file .gpx"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nascondi filtri"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrambi i valori di elevazione devono essere forniti ed essere maggiori o uguali a 0."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza dev'essere maggiore di 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durata dev'essere maggiore di 0"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi workout"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica più workout"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitudine massima"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file massimi"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dimensione massima"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocità massima"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitudine minima"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun segmento successivo"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimo workout"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data dal file gpx, senza \"pulizia\""])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun file scelto"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nessuna cartella all'interno"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna mappa"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun segmento successivo"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun workout successivo"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna nota"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun segmento precedente"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun workout precedente"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun record."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun workout."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pause"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segmento precedente"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workout precedente"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["record"]), _normalize(["record"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. media"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza più lunga"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salita più alta"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata più lunga"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. massima"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caratteri rimanenti"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segmento"]), _normalize(["segmenti"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocità"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sport"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizio"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio e fine"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partenza asse elevazione a 0"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titolo"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durata totale"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica file!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cielo chiaro"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notte chiara"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuvoloso"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebbia"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parzialmente nuvoloso"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notte parzialmente nuvolosa"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pioggia"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nevischio"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neve"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vento"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["umidità"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatura"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vento"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENE"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESE"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNE"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNW"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NW"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSE"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSW"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SW"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNW"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WSW"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senza file .gpx"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con file .gpx"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["allenamento"]), _normalize(["allenamenti"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data workout"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questo workout?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file .zip"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o file .zip contenente files .gpx"])}
}