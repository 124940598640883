<template>
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 170 170"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <desc id="paraglidingDescription">silhouette of a person paragliding</desc>
    <g id="g1" transform="rotate(-20,290.95281,-112.20439)">
      <path
        d="m 46.537,21.672 c 5.975,0 10.836,-4.861 10.836,-10.836 C 57.373,4.861 52.512,0 46.537,0 40.562,0 35.702,4.861 35.702,10.836 c 0,5.975 4.861,10.836 10.835,10.836 z"
        id="path1"
      />
      <path
        d="M 73.522,1.466 72.148,1.247 C 70.482,0.983 68.98,2.164 68.791,3.79 l -1.799,15.454 c -0.082,0.699 -0.769,1.523 -1.443,1.729 L 54.915,24.215 H 41.39 L 30.756,20.973 C 30.082,20.767 29.395,19.944 29.313,19.244 L 27.514,3.79 C 27.325,2.164 25.823,0.982 24.157,1.247 l -1.374,0.219 c -1.612,0.257 -2.768,1.785 -2.576,3.406 l 2.24,19.015 c 0.172,1.456 1.385,2.992 2.761,3.496 l 9.915,3.634 v 24.309 2.516 11.788 c 0,1.258 -0.551,3.229 -1.204,4.303 l -6.933,11.416 c -0.506,0.834 -0.629,1.815 -0.337,2.689 0.292,0.875 0.979,1.584 1.884,1.947 l 1.766,0.708 c 0.452,0.181 0.939,0.273 1.448,0.273 1.439,0 2.836,-0.752 3.559,-1.915 l 7.849,-12.628 c 0.846,-1.361 1.571,-3.682 1.65,-5.284 L 45.31,60.841 h 1.772 l 0.715,8.381 c 0.112,1.309 -0.085,3.485 -0.429,4.752 L 42.41,92.208 c -0.257,0.947 -0.101,1.919 0.429,2.668 0.53,0.749 1.395,1.219 2.373,1.292 l 1.722,0.127 c 0.092,0.007 0.184,0.01 0.276,0.01 1.813,0 3.588,-1.287 4.128,-2.993 L 56.67,76.468 c 0.455,-1.438 0.868,-3.836 0.92,-5.345 l 0.274,-7.979 c 0.049,-1.438 0.09,-3.67 0.09,-4.977 0,-0.055 -0.013,-0.11 -0.016,-0.165 0.003,-0.054 0.016,-0.105 0.016,-0.16 V 32.146 l 13.139,-4.768 c 1.379,-0.5 2.594,-2.034 2.766,-3.491 L 76.099,4.872 C 76.29,3.251 75.134,1.723 73.522,1.466 Z"
        id="path2"
      />
    </g>
    <g
      id="g2"
      transform="matrix(1.4695707,0,0,1.4846044,20.415877,-8.5848639)"
      style="clip-rule: evenodd; fill-rule: evenodd"
    >
      <path
        d="m 61.885982,5.8527592 c 9.068175,-0.479522 14.814087,0.9854051 22.617567,5.4216528 3.22924,5.308997 6.405887,11.427742 4.150014,17.329354 -4.309192,7.776985 -10.825847,17.817899 -15.601377,25.208217 -1.192082,-0.358378 -2.743044,0.453938 -3.822119,-0.176857 3.120809,-6.178779 8.754911,-16.042833 12.123246,-22.063838 -23.892839,-3.078508 -45.631423,2.691641 -65.215558,17.310548 -2.059352,1.158026 -2.708295,2.641787 -1.946733,4.451283 7.462509,3.46211 19.152836,6.597983 26.615345,10.060093 4.027664,0.77109 -0.336159,2.534732 -0.543972,4.160835 C 24.672282,61.368277 -4.4852033,57.747604 -2.842893,39.484681 1.7261979,29.691095 9.4494456,22.402111 17.676534,17.668715 31.777403,9.8075499 45.904905,6.3376279 61.885982,5.8527592 Z"
        id="path3"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Paragliding',
  }
</script>
