<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 522.362 522.362"
    style="enable-background: new 0 0 522.362 522.362"
    xml:space="preserve"
  >
    <desc id="alpineSkiingDescription">silhouette of a person skiing</desc>
    <g>
      <g>
        <path
          d="M14.314,351.859L408.088,461.78c4.484,1.311,9.104,1.97,13.731,1.97l0,0c17.471,0,33.746-9.429,42.467-24.604
			c2.716-4.724,3.137-10.27,1.157-15.214c-1.912-4.762-5.767-8.31-10.576-9.744c-7.373-2.237-15.481,1.109-22.146,9.229
			c-3.548,4.303-9.496,6.244-15.07,4.714l-136.467-38.078c1.396-0.555,2.812-1.195,4.236-2.065l103.266-63.15
			c15.443-9.362,18.493-33.957,7.812-43.883l-64.758-60.233l39.972-21.688l69.682,9.744c5.23,0.736,9.744-2.037,10.079-6.187
			c0.344-4.141-3.624-8.1-8.855-8.826l-48.605-6.828l22.472-12.192c39.895-23.275,5.823-85.192-35.334-63.112l-87.554,61.19
			l-117.503-16.515l2.543-12.087c0.545-2.582-1.109-5.116-3.691-5.661c-2.572-0.593-5.116,1.1-5.661,3.701l-2.678,12.709
			l-18.331-2.582c-5.231-0.727-9.467,2.123-9.467,6.369s4.236,8.281,9.467,9.017l15.176,2.123l-2.544,12.087
			c-0.545,2.582,1.109,5.125,3.691,5.671c0.334,0.076,0.66,0.105,0.994,0.105c2.209,0,4.198-1.54,4.676-3.787l2.678-12.737
			l102.414,14.315l-5.308,3.72c-4.332,3.022-10.155,9.151-11.723,14.201c-3.844,12.45-1.473,26.717,10.452,37.705l68.802,62.175
			c0,0-50.978,31.776-74.998,46.397c-14.219,8.606-13.674,23.858-6.129,33.393L23.705,318.199
			c-10.012-2.792-20.569,2.554-23.113,11.695C-2,339.169,4.159,349.029,14.314,351.859z"
        />
        <path
          d="M450.842,72.003c-15.291,16.715-14.201,42.667,2.639,58.121c16.706,15.31,42.716,14.086,58.073-2.668
			c15.386-16.677,14.172-42.734-2.544-58.016C492.305,54.064,466.17,55.192,450.842,72.003z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'SkiingAlpine',
  }
</script>
