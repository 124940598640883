export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["over"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestiging"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacteer"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["dag"]), _normalize(["dagen"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergegeven"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentatie"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verborgen"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startscherm"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oplopend"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aflopend"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorteer"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorteer op"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per pagina"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])}
}