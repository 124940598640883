export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle application OAuth2"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'application"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée le"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'application"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de redirection"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en écriture à la configuration de l'application."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en lecture aux routes <code>auth</code>."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en écriture aux routes <code>auth</code>."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en lecture aux routes <code>users</code>."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en écriture aux routes <code>users</code>."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en lecture aux routes <code>workouts</code>."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne les droits en écriture aux routes <code>workouts</code>."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de l'application"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications OAuth2"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application créée avec succès. Assurez-vous de copier le secret maintenant, il ne s'affichera plus."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette application ?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'application ", _interpolate(_list(0)), " demande les accès suivants :"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autoriser ", _interpolate(_list(0)), " à utiliser votre compte ?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copier dans le presse papier"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'application"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une application"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application introuvable !"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de applications"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de description"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer tous les jetons"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir révoquer tous les jetons ?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les jetons associés existants ont été révoqués."])}
}