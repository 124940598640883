export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engadir adestramento"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["análise"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascenso"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocidade media"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vel. media"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volver ao adestramento"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descenso"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar filtros"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distancia"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar adestramento"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitude"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desde"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ficheiro .gpx"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agochar filtros"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai que proporcionar os dous valores de altitude e deben ser iguais o maiores que 0."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A distancia debe ser superior a 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A duración debe superar os 0 segundos"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos adestramentos"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar máis adestramentos"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude máx."])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["máx. de ficheiros"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máx."])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. máxima"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude min."])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai tramo seguinte"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguinte adestramento"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datos directos do .gpx, sen limpar"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ficheiro"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sen cartafoles dentro"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen mapa"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai tramo seguinte"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai adestramento seguinte"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen notas"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai tramo anterior"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai adestramento anterior"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen récords."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen adestramentos."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pausas"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramo anterior"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adestramento anterior"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["récord"]), _normalize(["récords"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. media"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A maior distancia"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O maior desnivel"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A duración máis longa"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. máxima"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caracteres restantes"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tramo"]), _normalize(["tramos"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocidade"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deporte"]), _normalize(["deportes"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inicio"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio e fin"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["situar a altitude de inicio no cero da gráfica"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["título"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración total"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiches un!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["día despexado"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noite despexada"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nubrado"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["néboa"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["día algo nubrado"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noite algo nubrada"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chuvia"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sarabia"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neve"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vento"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["humidade"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatura"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vento"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XAN"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LSL"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNL"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNO"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SO"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONO"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSO"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sen ficheiro .gpx"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con ficheiro .gpx"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["adestramento"]), _normalize(["adestramentos"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data do adestramento"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes certeza de querer borrar este adestramento?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ficheiro .zip"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou ficheiro .zip que contén ficheiros .gpx"])},
  "DELETE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar o adestramento"])},
  "RESET_ZOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer vista"])},
  "VIEW_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en pantalla completa"])},
  "DOWNLOAD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar o adestramento"])},
  "EXIT_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saír da pantalla completa"])}
}