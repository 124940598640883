export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie potwierdzenie adresu e-mail"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzuj"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść filtry"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń moje konto"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zleć eksport danych"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
}