export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova applicazione OAuth2"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione applicazione"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue at"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome applicazione"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL redirect"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di scrittura alla configurazioen dell'applicazione."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di lettura a <code>auth</code> endpoints."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di scrittura a <code>auth</code> endpoints."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di lettura a <code>users</code> endpoints."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di scrittura a <code>users</code>endpoints."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di lettura a <code>workouts</code> endpoints."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da permessi di scrittura a <code>workouts</code> endpoints."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL applicazione"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazioni OAuth2"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazione creata. Sii certo di copiare il secret ora, non lo rivedrai più."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare quest'app?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'applicazione ", _interpolate(_list(0)), " sta chiedendo:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autorizzi ", _interpolate(_list(0)), " ad utilizzare il tuo account?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copia nella clipboard"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina applicazione"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi applicazione"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazione non trovata!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nessuna applicazione"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nessuna descrizione"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi tutti i tokens"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler rimuovere tutti i token?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i token esistenti associati sono stati rimossi."])}
}