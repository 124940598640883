export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le message de confirmation"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer mon compte"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un export de données"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinit."])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
}