export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzend bevestigings email"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekennen"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder filters"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder mijn account"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitzetten"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanzetten"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data export aanvragen"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
}