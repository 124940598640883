export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nową aplikację OAuth2"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekret"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis aplikacji"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydana"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa aplikacji"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL przekierowania"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakres"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do zapisu konfiguracji aplikacji."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do odczytu z adresów <code>auth</code>."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do odczytu z adresów <code>users</code>."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do odczytu z adresów <code>workouts</code>."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do zapisu na adresach <code>auth</code>."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do zapisu na adresach <code>users</code>."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadaje dostęp do zapisu na adresach <code>workouts</code>."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL aplikacji"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacje OAuth2"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie istniejące powiązane tokeny zostały odwołane."])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń aplikację"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja nie znaleziona!"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj aplikację"])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć tę aplikację?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skopiuj do schowka"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brak aplikacji"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez opisu"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zezwolić ", _interpolate(_list(0)), " by używała twojego konta?"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja pomyślnie utworzona. Upewnij się teraz, aby skopiować sekret. On już się więcej nie wyświetli."])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aplikacja ", _interpolate(_list(0)), " wnioskuje o:"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno odwołać wszystkie tokeny?"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odwołaj wszystkie tokeny"])}
}