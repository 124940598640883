<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 535.876 535.876"
    style="enable-background: new 0 0 535.876 535.876"
    xml:space="preserve"
  >
    <desc id="trailDescription">silhouette of a person running (trail)</desc>
    <g>
      <g>
        <path
          d="M505.204,326.949c-5.805-10.892-15.176-10.862-20.923,0.067l-7.114,13.512c-5.757,10.92-20.416,19.775-32.752,19.775
			h-16.753c-12.346,0-29.645,6.847-38.643,15.29l-32.35,30.342l-1.233-105.388c0.822-18.446-10.596-24.634-10.596-24.634
			l-56.543-25.972l33.67-67.645l38.527,22.912c0,0,15.472,9.715,26.727-9.324l37.026-63.093c1.625-3.519,2.094-7.564,1.052-11.609
			c-2.381-9.256-11.81-14.86-21.095-12.479c-4.083,1.042-7.459,3.49-9.754,6.713l-30.772,51.962l-62.777-37.102
			c-4.733-2.716-10.519-3.691-16.257-2.209l-78.345,20.12c-5.03,1.291-8.96,4.762-11.017,9.142l-38.097,76.165
			c-2.591,3.854-3.624,8.73-2.371,13.607c2.285,8.941,11.456,14.363,20.435,12.059c5.04-1.291,8.951-4.743,10.997-9.161
			l35.314-70.858l28.936-7.296l-76.203,149.921l-65.981,16.734c-1.759,0.393-3.48,1.023-5.163,1.836
			c-10.596,5.412-14.679,18.179-9.267,28.803c4.733,9.266,15.252,13.636,24.901,10.978l78.067-20.187
			c18.6-4.093,23.313-14.449,23.313-14.449l30.581-58.79l62.28,35.678l0.593,86.216c0.01,1.08,0.259,2.161,0.421,3.241l-11.6-4.188
			c-11.608-4.188-30.57-4.608-42.361-0.956l-26.135,8.128c-11.79,3.663-30.791,3.338-42.447-0.717l-19.221-6.694
			c-11.657-4.054-28.831-0.984-38.374,6.838L109.9,433.954c-9.544,7.822-26.67,10.72-38.25,6.483L40.84,429.135
			c-11.58-4.256-25.608,1.157-31.336,12.097l-7.21,13.789c-5.728,10.93-0.354,19.794,11.982,19.794H500.27
			c12.336,0,24.309-9.802,26.728-21.907l8.004-39.981c2.409-12.097-0.325-30.733-6.14-41.616L505.204,326.949z"
        />
        <path
          d="M326.395,126.625c17.806-4.562,28.563-22.721,23.983-40.526c-4.581-17.844-22.74-28.554-40.555-23.983
			c-17.796,4.581-28.535,22.711-23.964,40.535C290.439,120.476,308.599,131.205,326.395,126.625z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Trail',
  }
</script>
