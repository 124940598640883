<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 492.508 492.508"
    style="enable-background: new 0 0 492.508 492.508"
    xml:space="preserve"
  >
    <desc id="openWaterSwimmingDescription">
      silhouette of a person swimming in open water
    </desc>
    <g id="g1" transform="matrix(0.81,0,0,0.81,46.788307,142.78824)">
      <path
        d="m 352.833,212.454 c 25.065,-2.014 43.752,-23.962 41.738,-49.018
        -2.014,-25.064 -23.961,-43.751 -49.028,-41.736 -25.064,2.006
        -43.749,23.953 -41.734,49.019 2.013,25.063 23.959,43.749
        49.024,41.735 z"
        id="path1"
      />
      <path
        d="m 79.914,188.791 83.2,-30.884 49.382,22.5 -104.096,46.997 c
        4.361,1.942 8.598,4.171 12.482,7.041 24.057,-17.623 59.496,-17.615
        83.524,0.008 23.864,-17.688 59.255,-17.8 83.537,-0.056 5.484,-4.499
        9.862,-10.263 12.405,-17.009 3.836,-10.149 3.482,-21.411 -0.99,-31.307
        -3.022,-6.696 -11.238,-15.226 -24.282,-21.108 -14.993,-6.762
        -102.675,-47.803 -102.675,-47.803 -4.923,-2.263 -10.58,-2.455
        -15.6,-0.567 l -91.115,33.824 c -10.601,3.932 -16.001,15.705
        -12.069,26.304 3.934,10.598 15.793,15.984 26.297,12.06 z"
        id="path2"
      />
      <path
        d="m 487.729,258.735 c -6.396,-6.412 -16.771,-6.395 -23.167,-0.016
        -5.143,5.147 -13.537,5.132 -18.652,0.016 -17.248,-17.264
        -47.684,-17.296 -64.98,0.016 -4.955,4.955 -13.617,4.955
        -18.603,-0.016 -17.313,-17.369 -47.572,-17.385 -64.949,0
        -4.987,4.971 -13.654,4.971 -18.641,0 l -0.959,-0.927 c
        -17.36,-16.402 -46.994,-16.075 -64.055,0.959 -4.891,4.907
        -13.617,4.931 -18.604,-0.024 -17.072,-17.112 -47.877,-17.136
        -64.98,0.008 -4.891,4.923 -13.634,4.955 -18.593,-0.032 -17.372,-17.353
        -47.601,-17.313 -64.913,-0.008 -5.195,5.155 -13.589,5.131
        -18.688,0.008 -6.396,-6.379 -16.768,-6.396 -23.162,0.016 -6.38,6.394
        -6.38,16.761 0.016,23.146 17.902,17.872 47.03,17.88 64.965,-0.008
        4.986,-4.971 13.682,-4.947 18.604,-0.025 17.329,17.385 47.621,17.385
        64.949,0.032 4.891,-4.923 13.67,-4.979 18.688,0.04 8.679,8.656
        20.205,13.427 32.435,13.427 12.277,0 23.8,-4.778 32.498,-13.491
        4.955,-4.947 13.682,-4.939 18.605,-0.008 l 0.976,0.927 c 17.408,16.514
        46.949,16.137 64.006,-0.895 5.019,-5.003 13.649,-5.003 18.636,-0.016
        17.36,17.385 47.605,17.369 64.965,-0.016 4.923,-4.923 13.653,-4.963
        18.641,0.041 17.901,17.88 47.042,17.84 64.948,-0.008 6.389,-6.385
        6.389,-16.752 0.014,-23.146 z"
        id="path3"
      />
      <path
        d="m 382.703,339.052 -1.773,1.535 c -4.923,4.939 -13.665,4.915
        -18.588,0.008 -17.313,-17.385 -47.557,-17.4 -64.965,-0.032
        -4.987,5.003 -13.654,4.987 -18.641,0.016 l -0.959,-0.927 c
        -17.36,-16.401 -46.994,-16.075 -64.055,0.959 -4.891,4.907
        -13.617,4.931 -18.604,-0.024 -17.184,-17.24 -47.701,-17.249
        -64.965,-0.008 -4.906,4.931 -13.649,4.939 -18.592,0.008
        -6.376,-6.401 -16.735,-6.418 -23.146,-0.032 -6.408,6.377
        -6.408,16.745 -0.032,23.146 17.2,17.256 47.732,17.256 64.933,0.016
        4.955,-4.963 13.637,-5.011 18.688,0.048 8.679,8.656 20.205,13.426
        32.435,13.426 12.277,0 23.8,-4.778 32.498,-13.491 4.955,-4.947
        13.682,-4.939 18.605,-0.008 l 0.976,0.927 c 17.44,16.522 46.982,16.145
        64.022,-0.911 4.986,-4.971 13.648,-4.995 18.637,0.024 17.123,17.096
        47.303,17.232 64.551,0.351 6.043,-5.633 6.969,-15.018 1.902,-21.772
        -5.438,-7.239 -15.701,-8.709 -22.927,-3.259 z"
        id="path4"
      />
    </g>
    <g id="g2" transform="matrix(0.704,0,0,0.704,36.10299,2.3995977)">
      <path
        d="m 109.881,183.46 c -4.142,0 -7.5,3.358 -7.5,7.5 v 21.324 c 0,4.142
        3.358,7.5 7.5,7.5 4.143,0 7.5,-3.358 7.5,-7.5 V 190.96 c 0,-4.143
        -3.358,-7.5 -7.5,-7.5 z"
        id="path5"
      />
      <path
        d="m 109.881,36.329 c 4.143,0 7.5,-3.358 7.5,-7.5 V 7.503 c 0,-4.142
        -3.357,-7.5 -7.5,-7.5 -4.142,0 -7.5,3.358 -7.5,7.5 v 21.326 c 0,4.142
        3.358,7.5 7.5,7.5 z"
        id="path6"
      />
      <path
        d="m 47.269,161.909 -15.084,15.076 c -2.93,2.928 -2.931,7.677
        -0.003,10.606 1.465,1.465 3.385,2.198 5.305,2.198 1.919,0
        3.837,-0.732 5.302,-2.195 l 15.084,-15.076 c 2.93,-2.928 2.931,-7.677
        0.003,-10.606 -2.93,-2.93 -7.678,-2.93 -10.607,-0.003 z"
        id="path7"
      />
      <path
        d="m 167.208,60.067 c 1.919,0 3.838,-0.732 5.303,-2.196 l
        15.082,-15.076 c 2.929,-2.929 2.93,-7.677 0.002,-10.607 -2.929,-2.93
        -7.677,-2.931 -10.607,-0.001 l -15.082,15.076 c -2.929,2.928
        -2.93,7.677 -0.002,10.606 1.464,1.466 3.384,2.198 5.304,2.198 z"
        id="path8"
      />
      <path
        d="m 36.324,109.895 c 0,-4.142 -3.358,-7.5 -7.5,-7.5 H 7.5 c -4.142,0
        -7.5,3.358 -7.5,7.5 0,4.142 3.358,7.5 7.5,7.5 h 21.324 c 4.142,0
        7.5,-3.358 7.5,-7.5 z"
        id="path9"
      />
      <path
        d="m 212.286,102.395 h -21.334 c -4.143,0 -7.5,3.358 -7.5,7.5 0,4.142
        3.357,7.5 7.5,7.5 h 21.334 c 4.143,0 7.5,-3.358 7.5,-7.5 0,-4.141
        -3.357,-7.5 -7.5,-7.5 z"
        id="path10"
      />
      <path
        d="m 47.267,57.871 c 1.464,1.464 3.384,2.196 5.303,2.196 1.919,0
        3.839,-0.732 5.303,-2.196 2.929,-2.929 2.929,-7.678 0,-10.607 L
        42.797,32.188 c -2.929,-2.929 -7.678,-2.929 -10.606,0 -2.929,2.929
        -2.929,7.678 0,10.606 z"
        id="path11"
      />
      <path
        d="m 172.52,161.911 c -2.929,-2.929 -7.678,-2.93 -10.607,-0.001
        -2.93,2.929 -2.93,7.678 -0.001,10.606 l 15.074,15.076 c 1.465,1.465
        3.384,2.197 5.304,2.197 1.919,0 3.839,-0.732 5.303,-2.196 2.93,-2.929
        2.93,-7.678 0.001,-10.606 z"
        id="path12"
      />
      <path
        d="m 109.889,51.518 c -32.187,0 -58.373,26.188 -58.373,58.377 0,32.188
        26.186,58.375 58.373,58.375 32.19,0 58.378,-26.187 58.378,-58.375
        0,-32.189 -26.189,-58.377 -58.378,-58.377 z m 0,101.752 c -23.916,0
        -43.373,-19.458 -43.373,-43.375 0,-23.918 19.457,-43.377 43.373,-43.377
        23.919,0 43.378,19.459 43.378,43.377 0,23.917 -19.459,43.375
        -43.378,43.375 z"
        id="path13"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'OpenWaterSwimming',
  }
</script>
