<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 494.49 494.49"
    style="enable-background: new 0 0 494.49 494.49"
    xml:space="preserve"
  >
    <desc id="runningDescription">silhouette of a running person</desc>
    <g>
      <path
        d="M282.74,80.771c22.318,0,40.401-18.08,40.401-40.389C323.141,18.084,305.058,0,282.74,0
		c-22.281,0-40.378,18.084-40.378,40.383C242.362,62.691,260.458,80.771,282.74,80.771z"
      />
      <path
        d="M400.207,188.547H331.47l-38.766-55.03l0.123-0.944c1.384-10.514-1.475-21.146-7.94-29.556
		c-6.461-8.409-16.007-13.903-26.52-15.287c-10.926-1.429-22.619,3.12-31.206,8.646c-1.441,0.928-84.97,54.921-84.97,54.921
		c-5.175,3.358-8.542,8.877-9.165,15.016c-0.634,6.13,1.574,12.222,5.976,16.541l58.982,58l-6.417,48.954l-18.707,65.584l-67.8-19.4
		c-12.911-3.676-26.44,3.796-30.159,16.747c-3.699,12.951,3.799,26.459,16.758,30.168l91.271,26.109
		c2.192,0.627,4.444,0.936,6.7,0.936c4.113,0,8.195-1.04,11.848-3.073c5.655-3.146,9.833-8.409,11.611-14.635l21.963-77.057
		l26.365,36.639l6.684,119.628c0.73,12.991,11.501,23.036,24.349,23.036c0.441,0,0.92-0.016,1.379-0.039
		c13.453-0.748,23.745-12.262,23-25.713l-7.083-126.736c-0.271-4.643-1.846-9.116-4.56-12.887l-32.24-44.811l11.959-91.279
		l19.409,27.555c3.794,5.407,10.005,8.624,16.613,8.624h79.28c11.226,0,20.326-9.101,20.326-20.329
		C420.533,197.647,411.432,188.547,400.207,188.547z M204.606,190.357l-19.026-18.717l23.476-15.206L204.606,190.357z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Running',
  }
</script>
