<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 534.51 534.51"
    style="enable-background: new 0 0 534.51 534.51"
    xml:space="preserve"
  >
    <desc id="rowingDescription">silhouette of a person rowing</desc>
    <g>
      <g>
        <path
          d="M70.517,393.857h132.622l-67.205,68.631c-3.28,3.385-3.204,8.797,0.172,12.076c1.597,1.568,3.72,2.43,5.958,2.43
			c2.305,0,4.533-0.947,6.12-2.572l78.881-80.555h221.315c12.45,0,30.676-6.006,40.296-13.914
			c8.788-7.229,19.049-16.217,26.345-24.299c10.375-9.811,22.214-23.639-4.255-22.834c-6.98,0.248-206.789,0.02-223.926,0
			l47.144-48.139l22.176-22.032l28.209,9.17c10.414,3.385,22.472-0.803,26.919-9.362c4.446-8.558,0.268-18.407-9.343-21.993
			l-17.404-6.512l54.698-54.774c12.507,6.617,28.362,4.867,38.899-5.671l46.541-46.607c13.11-13.015,13.11-33.957,0-46.923
			c-12.909-13.034-34.042-13.034-46.942,0l-46.512,46.598c-10.892,10.815-12.508,27.139-5.26,39.742l-57.853,59.077L253.744,183.09
			c-1.482-0.851-14.153-5.786-26.833-10.643c-14.898-5.719-30.371,0.583-34.53,13.971l-7.525,24.241l-35.324,118.451
			c-0.468,1.34-0.603,2.562-0.746,3.711H14.136c-12.45,0-17.662,8.836-11.618,19.727l23.237,21.592
			C39.123,385.068,58.066,393.857,70.517,393.857z M272.63,248.727c1.797-5.967,8.099-9.39,14.075-7.64l51.37,14.975l-33.038,33.737
			l-43.453,43.012h-14.2L272.63,248.727z"
        />
        <circle cx="248.953" cy="109.842" r="52.326" />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Rowing',
  }
</script>
