export default {
  "Cycling (Sport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo (Sport)"])}
  },
  "Cycling (Transport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclismo (Trasporto)"])}
  },
  "Cycling (Virtual)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cicliscmo (Virtuale)"])}
  },
  "Hiking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escursioni"])}
  },
  "Mountain Biking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mountain Biking"])}
  },
  "Mountain Biking (Electric)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mountain Biking (Elettrica)"])}
  },
  "Mountaineering": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpinismo"])}
  },
  "Rowing": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canottaggio"])}
  },
  "Running": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsa"])}
  },
  "Skiing (Alpine)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci (Alpino)"])}
  },
  "Skiing (Cross Country)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci (Cross Country)"])}
  },
  "Snowshoes": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racchette da neve"])}
  },
  "Trail": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentieri"])}
  },
  "Walking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camminata"])}
  }
}