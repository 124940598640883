<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 491.737 491.737"
    style="enable-background: new 0 0 491.737 491.737"
    xml:space="preserve"
  >
    <desc id="cyclingSportDescription">
      silhouette of a person riding a bicycle
    </desc>
    <g>
      <path
        d="M321.097,112.359c20.973,12.338,47.985,5.315,60.293-15.652c12.34-20.973,5.35-47.974-15.623-60.304
		c-21.009-12.332-47.99-5.317-60.314,15.65C293.129,73.036,300.103,100.027,321.097,112.359z"
      />
      <path
        d="M393.081,264.102c-2.414,0-4.8,0.194-7.169,0.362l-14.431-71.605l4.702-1.757c10.666-3.987,16.093-15.868,12.098-26.54
		c-3.994-10.681-15.946-16.084-26.531-12.09l-51.823,19.38l-2.321-18.864c6.3-13.193,5.541-29.78-4.767-41.482
		c-21.224-24.092-47.12-12.508-55.191-5.976l-106.884,86.555l0.016,0.024c-3.319,2.893-6.089,6.485-7.86,10.842
		c-2.191,5.396-2.596,11.067-1.564,16.384c-8.503,0.669-15.255,7.571-15.255,16.246c0,9.085,7.346,16.44,16.432,16.48l-6.797,15.906
		c-8.62-2.465-17.674-3.866-27.066-3.866C44.27,264.102,0,308.354,0,362.754c0,54.403,44.27,98.663,98.668,98.663
		c54.403,0,98.652-44.26,98.652-98.663c0-36.228-19.683-67.867-48.858-85.024l10.957-25.652h17.767l60.281,24.462l-32.201,52.773
		c-8.297,13.612-3.994,31.382,9.615,39.685c4.691,2.86,9.878,4.229,15,4.229c9.729,0,19.234-4.929,24.677-13.838l29.339-48.095
		l19.072,11.511c-5.447,12.227-8.54,25.726-8.54,39.95c0,54.403,44.254,98.663,98.652,98.663c54.402,0,98.656-44.26,98.656-98.663
		C491.737,308.354,447.483,264.102,393.081,264.102z M98.668,436.671c-40.756,0-73.923-33.161-73.923-73.917
		c0-40.756,33.167-73.909,73.923-73.909c5.944,0,11.649,0.896,17.188,2.224l-20.476,47.893
		c-11.758,1.619-20.843,11.598-20.843,23.792c0,13.323,10.808,24.132,24.13,24.132c8.767,0,16.367-4.745,20.589-11.76h52.065
		C165.395,409.988,135.188,436.671,98.668,436.671z M171.322,350.383h-52.065c-0.355-0.588-0.708-1.176-1.112-1.732l20.476-47.901
		C155.679,311.776,167.793,329.595,171.322,350.383z M296.781,290.175l7.666-12.564c4.416-7.233,5.431-16.038,2.774-24.084
		c-2.661-8.046-8.718-14.515-16.562-17.704l-52.725-21.395l32.443-26.281l1.804,14.691c0.756,6.267,4.366,11.841,9.761,15.12
		c3.271,1.981,6.979,2.988,10.698,2.988c2.435,0,4.88-0.435,7.218-1.306l48.15-18.001l13.627,67.691
		c-18.268,6.162-34.117,17.51-45.848,32.314L296.781,290.175z M375.396,337.633l-38.003-22.94
		c7.877-9.118,17.787-16.319,29.205-20.734L375.396,337.633z M393.081,436.671c-40.757,0-73.907-33.161-73.907-73.917
		c0-9.544,1.965-18.597,5.268-26.983l44.541,26.888c0,0.032-0.016,0.064-0.016,0.095c0,13.323,10.808,24.132,24.114,24.132
		c13.322,0,24.118-10.81,24.118-24.132c0-10.478-6.721-19.307-16.06-22.64l-10.277-51.043c0.756-0.024,1.463-0.226,2.22-0.226
		c40.757,0,73.911,33.153,73.911,73.909C466.992,403.51,433.838,436.671,393.081,436.671z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'CyclingSport',
  }
</script>
