export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir entrenamiento"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["análisis"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascenso"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocidad media"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vel. media"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volver al entrenamiento"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descenso"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar filtros"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distancia"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiempo"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar el entranamiento"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitud"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desde"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichero .gpx"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ocultar filtros"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes proporcionar ambos valores de altura y tienen que ser igual o superior a 0."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia debe ser mayor de 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La duración debe ser mayor que 0 segundos"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos entrenamientos"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar más entrenamientos"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitud máxima"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["máx. de ficheros"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tamaño máximo"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocidad máxima"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitud mínima"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin tramo siguiente"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente entrenamiento"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datos desde gpx, sin comprobar"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin fichero proporcionado"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no hay carpeta dentro"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin mapa"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tramo siguiente"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay entrenamiento siguiente"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin notas"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tramo anterior"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay entrenamiento anterior"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin registros."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin entrenamientos."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pausas"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramo anterior"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenamiento anterior"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["registro"]), _normalize(["registros"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vel. media"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia mayor"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascenso mayor"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración más larga"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad máxima"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caracteres restantes"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tramo"]), _normalize(["tramos"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velocidad"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deporte"]), _normalize(["deportes"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inicio"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio y final"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poner la altitud de inicio en el cero del gráfico"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["título"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración total"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una subida!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["día despejado"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noche despejada"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nublado"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niebla"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["día parcialmente nublado"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noche parcialmente nublada"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lluvia"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granizo"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieve"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viento"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["humedad"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatura"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viento"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENE"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESE"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNE"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNO"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSE"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SO"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONO"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSO"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin fichero .gpx"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con fichero .gpx"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["entrenamiento"]), _normalize(["entrenamientos"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha del entrenamiento"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que quieres eliminar este entrenamiento?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichero .zip"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o fichero .zip que contiene ficheros .gpx"])},
  "EXIT_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de la pantalla completa"])},
  "RESET_ZOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer el zoom"])},
  "DELETE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el entrenamiento"])},
  "VIEW_FULLSCREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver a pantalla completa"])},
  "DOWNLOAD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga el entrenamiento"])}
}