export default {
  "CONTENT": {
    "ACCOUNT_DELETION": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst die Löschung deines Kontos jederzeit anfragen, indem du (nach dem Anmelden) zu dieser Adresse gehst und auf den „Mein Konto löschen“-Button in der Accountbearbeitung klickst."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account löschen"])}
    },
    "CHANGES_TO_OUR_PRIVACY_POLICY": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn wir uns dazu entschließen, unsere Datenschutzrichtlinie zu ändern, werden wir die Änderungen auf dieser Seite posten.\n\nDieses Dokument ist unter der [CC-BY-SA-Lizenz](https://creativecommons.org/licenses/by-sa/4.0/). Angepasst von der Datenschutzrichtlinie von [Discourse](https://github.com/discourse/discourse)."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen unserer Datenschutzrichtlinie"])}
    },
    "DATA_COLLECTED": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Informationen werden gesammelt:\n- Accountinformationen (Benutzername, E-Mail-Adresse und Password). Du kannst auch zusätzliche Profilinformationen wie Vor- und Nachname, Geburtsdatum, Standort und Biographie eingeben und ein Profilbild hochladen.\n- [GPX](https://de.wikipedia.org/wiki/GPS_Exchange_Format)-Dateien. Diese Dateien enthalten Daten, die mit deinen Aktivitäten zusammenhängen (geographische Koordinaten, Datum, Distanz, Dauer, maximale und durchschnittliche Geschwindigkeit, Höhe, Pulsrate...). Wenn du einige dieser Daten nicht preisgeben möchtest, kannst du die Dateien vor dem Hochladen bereinigen oder Workouts ohne GPX-Dateien hochladen.\n- Workout-Daten (Sportart, Titel, Datum, Dauer, Distanz, An- und Abstieg, Notizen).\n- Technische Informationen (Browser und Betriebssystem)."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Informationen sammeln wir?"])}
    },
    "INFORMATION_DISCLOSURE": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verkaufen deine personenbezogenen Informationen nicht und übertragen sie auch nicht an Dritte.\n\nDies enthält nicht vertrauliche Dritte die uns beim Betreiben dieser Seiten helfen, solange diese Parteien zustimmen, die Informationen vertraulich zu behandeln.\n\nWir können auch Informationen preisgeben, wenn wir denken, dass dieses Preisgeben angemessen ist, um mit dem Gesetz übereinzustimmen, unsere Seitenrichtlinien durchzusetzen oder um unsere oder die Rechte und Sicherheit anderer zu schützen.\n\nWenn du einer Anwendung von Dritten die Berechtigung gibst, deinen Account zu nutzen, abhängig von den Berechtigungen, denen du zustimmst, können sie möglicherweise auf Profilinformationen oder Workouts zugreifen. Anwendungen können niemals dein Passwort einsehen."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben wir Informationen an Außenstehende weiter?"])}
    },
    "INFORMATION_PROTECTION": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir implementieren eine Reihe von Sicherheitsmaßnahmen, um die Sicherheit deiner persönlichen Informationen zu gewährleisten, wenn du diese eingibst oder auf sie zugreifst."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie schützen wir deinen Informationen?"])}
    },
    "INFORMATION_USAGE": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese gesammelten Informationen können verwendet werden, um die Kernfunktionen von **FitTrackee** zu gewährleisten:\n- GPX-Dateien werden verwendet, um Workouts zu erstellen, Tracks auf Karten (mit [OpenStreetMap](https://www.openstreetmap.org) und dem konfigurierten Tile-Server) und Diagrammen darzustellen, Karten-Thumbnails zu erstellen, Rekorde zu berechnen und Wetterdaten zu erhalten (wenn ein Wetteranbieter gesetzt wurde).\n- Profilinformationen und Workouts werden nicht öffentlich angezeigt. Ein registrierter Nutzen kann nur seine eigenen Workouts anzeigen.\n- Die E-Mail-Adresse, die du angibst, kann verwendet werden, um dir Informationen oder Bestätigungen zu Accountänderungen zu schicken."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für was verwenden wir deine Informationen?"])}
    },
    "SITE_USAGE_BY_CHILDREN": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn dieser Server in der EU oder EEA steht: Unsere Seite und Services sind für Personen, die mindestens 16 Jahre alt sind. Wenn du unter 16 bist, benutze diese Seite gemäß den Bestimmungen der [DSGVO](https://de.wikipedia.org/wiki/Datenschutz-Grundverordnung) (Datenschutz-Grundverordnung) nicht.\n\nWenn dieser Server in den USA steht: Unsere Seite und Services sind für Personen, die mindestens 13 Jahre alt sind. Wenn du unter 13 bist, benutze diese Seite gemäß den Bestimmungen der [COPPA](https://de.wikipedia.org/wiki/Children%E2%80%99s_Online_Privacy_Protection_Act) (Children’s Online Privacy Protection Act) nicht.\n\nGesetzliche Anforderungen können in anderen Rechtsbereichen anders sein."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitennutzung durch Kinder"])}
    },
    "YOUR_CONSENT": {
      "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch das Benutzen unserer Seite stimmst du der Datenschutzrichtlinie der Webseite zu."])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zustimmung"])}
    }
  },
  "LAST_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Update"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])}
}