<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 296 296"
    style="enable-background: new 0 0 296 296"
    xml:space="preserve"
  >
    <desc id="crossCountrySkiingDescription">
      silhouette of a person skiing (cross country)
    </desc>
    <g>
      <path
        d="M241.313,246.906h-39.564l0.456-1.273c5.62-18.374,3.479-37.58-6.027-54.278c-5.653-9.929-13.443-18.018-22.745-23.959
		l12.886-51.126c3.035-12.05-4.272-24.266-16.322-27.301c-2.634-0.664-5.278-0.826-7.833-0.559
		c-0.151-0.011-0.299-0.032-0.452-0.038c-26.03-1.077-51.443,8.485-70.33,25.993L41.586,82.855
		c-4.332-2.741-10.065-1.451-12.806,2.881s-1.451,10.065,2.88,12.806l47.625,30.136c-2.557,6.013-0.962,13.224,4.375,17.531
		c2.774,2.24,6.104,3.33,9.413,3.33c4.377-0.001,8.717-1.907,11.68-5.579l0.478-0.591c8.686-10.766,20.465-18.479,33.484-22.317
		l-11.095,44.046c-1.061,4.214-0.854,8.448,0.374,12.305c-12.908,17.917-28.398,33.68-46.198,46.979l-14.649,10.727
		c-3.938,2.943-6.001,7.798-6.005,11.798H8.25c-4.556,0-8.083,4.131-8.083,8.688v2.063c0,4.556,3.527,8.25,8.083,8.25h55.188
		h161.375H280c9.113,0,16.167-7.387,16.167-16.5v-2.5H241.313z M153.974,192.398c0.741-0.153,1.471-0.231,2.188-0.457
		c5.742,3.406,10.53,8.313,13.945,14.311c5.378,9.447,6.59,20.402,3.41,30.797l-1.799,5.674c-0.447,1.461-0.656,3.184-0.657,4.184
		h-69.909C121.581,231.906,139.297,213.274,153.974,192.398z M167,57.938c0-15.378,12.466-27.844,27.844-27.844
		s27.844,12.466,27.844,27.844s-12.466,27.844-27.844,27.844S167,73.315,167,57.938z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'SkiingCrossCountry',
  }
</script>
