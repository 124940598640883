export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en arbeidsøkt"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyse"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilbake til arbeidsøkt"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dato"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avstand"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger arbeidsøkten"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avstanden må være større enn 0"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. størrelse"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maks. hastighet"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste arbeidsøkt"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notater"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fil angitt"])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbeidsøkter."])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige segment"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengste varighet"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. hastighet"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gjenstående tegn"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segment"]), _normalize(["segmenter"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hastighet"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sporter"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start og slutt"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["til"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total varighet"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tøm dag"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regn"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["snø"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vind"])}
    },
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatur"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vind"])}
  },
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".zip-fil"])}
}