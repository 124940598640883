<template>
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 494.49 494.49"
    style="enable-background: new 0 0 494.49 494.49"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <desc id="snowshoesDescription">silhouette of a person with snowshoes</desc>
    <a id="a941">
      <g id="g6">
        <path
          d="M282.74,80.771c22.318,0,40.401-18.08,40.401-40.389C323.141,18.084,305.058,0,282.74,0   c-22.281,0-40.378,18.084-40.378,40.383C242.362,62.691,260.458,80.771,282.74,80.771z"
          id="path2"
        />
        <path
          d="M 400.207,188.547 H 331.47 l -38.766,-55.03 0.123,-0.944 c 1.384,-10.514 -1.475,-21.146 -7.94,-29.556 -6.461,-8.409 -16.007,-13.903 -26.52,-15.287 -10.926,-1.429 -22.619,3.12 -31.206,8.646 -1.441,0.928 -84.97,54.921 -84.97,54.921 -5.175,3.358 -8.542,8.877 -9.165,15.016 -0.634,6.13 1.574,12.222 5.976,16.541 l 58.982,58 -6.417,48.954 -18.707,65.584 -65.05119,31.97053 c -12.988666,4.46731 -20.184548,18.48046 -15.989971,31.3127 4.211472,12.82063 18.196961,19.59882 31.244871,15.13083 L 197.478,400.879 c 5.655,-3.146 9.833,-8.409 11.611,-14.635 l 25.963,-71.057 26.365,36.639 6.684,83.628 c 0.73,12.991 11.501,23.036 24.349,23.036 0.441,0 0.92,-0.016 1.379,-0.039 13.453,-0.748 23.745,-12.262 23,-25.713 l -7.083,-90.736 c -0.271,-4.643 -1.846,-9.116 -4.56,-12.887 l -32.24,-44.811 11.959,-91.279 19.409,27.555 c 3.794,5.407 10.005,8.624 16.613,8.624 h 79.28 c 11.226,0 20.326,-9.101 20.326,-20.329 0,-11.228 -9.101,-20.328 -20.326,-20.328 z m -195.601,1.81 -19.026,-18.717 23.476,-15.206 z"
          id="path4"
        />
      </g>
    </a>
    <path
      d="m 298.71755,466.89876 c 34.77016,0 59.21435,1.29141 59.21435,-13.23956 0,-14.53096 4.12606,-16.02074 -29.36635,-12.11757 -30.73634,3.582 -85.42111,4.42691 -90.30047,14.96259 -4.90812,10.59778 25.68237,10.39454 60.45247,10.39454 z"
      id="path4-3"
      style="stroke-width: 3.76193"
    />
    <path
      d="m 87.671049,426.72238 c 15.942661,30.89977 26.003031,53.21513 38.916511,46.55245 12.91346,-6.66266 16.12928,-3.67899 -2.6962,-31.65357 C 106.615,415.9487 80.790319,367.73849 69.190141,368.23304 c -11.668553,0.49747 2.538273,27.58962 18.480908,58.48934 z"
      id="path4-3-3"
      style="stroke-width: 3.76193"
    />
    <rect
      style="stroke-width: 0.701998"
      id="rect1062"
      width="9.7789898"
      height="242.75766"
      x="397.1806"
      y="203.58122"
      ry="4.8894949"
    />
    <rect
      style="stroke-width: 0.701998"
      id="rect1062-7"
      width="9.7789898"
      height="242.75766"
      x="296.39819"
      y="-78.536827"
      ry="4.8894949"
      transform="rotate(54.243176)"
    />
  </svg>
</template>

<script>
  export default {
    name: 'Snowshoes',
  }
</script>
