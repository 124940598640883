export default {
  "APP_CONFIG": {
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brak adresu e-mail do kontaktu"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli 0, to nie ma limitu rejestracji."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. liczba aktywnych użytkowników"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. wielkość nadesłanych plików (w Mb)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja aplikacji"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. wielkość archiwum zip (w Mb)"])},
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktowy e-mail administratora"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. plików archiwum zip"])}
  },
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Czy na pewno chcesz zresetować ", _interpolate(_list(0)), " hasło?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny e-mail"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłanie e-maili jest wyłączone."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz/wyłącz sporty."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy e-mail"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie podano tekstu"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło zostało zresetowane."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj e-mail"])},
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["użytkownik"]), _normalize(["użytkowników"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nazwa użytkownika"])},
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status administratora"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data rejestracji"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status konta"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba treningów"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj uprawnienia administratora"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń uprawnienia administratora"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja - Użytkownicy"])}
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail został zaktualizowany."])},
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ewentualne dodatkowe informacje, które mogą być użyteczne użytkownikom. Można używać składni Markdown."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegółowe informacje o instancji"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcja"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń użytkownika"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["istnieją treningi"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obraz"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etykieta"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie sportami"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj konfigurację aplikacji."])},
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do administratora"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Czy na pewno usunąć konto ", _interpolate(_list(0)), "? Wszystkie dane zostaną skasowane, tego nie da się cofnąć."])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj konto"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj/usuń uprawnienia administratora, usuń konto użytkownika."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja"])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj swoją politykę prywatności lub zostaw puste aby użyć domyślnej. Można używać składni Markdown."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakładanie kont jest wyłączone."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakładanie kont jest włączone."])}
}