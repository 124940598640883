export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contatto"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["giorno"]), _normalize(["giorni"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrato"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentazione"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascosto"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dal più basso al più alto"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dal più alto al più basso"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordina"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordina per"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per pagina"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])}
}