export default {
  "ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account confirmation"])},
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't received instructions?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email. A new confirmation email has been sent to the address provided."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account? All data will be deleted, this cannot be undone."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address."])},
  "EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email update"])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a password"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data export"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download archive"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generating link..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can request an archive by 24 hours"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errored (please request another export)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in progres..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter on username"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide password"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token, please request a new password reset."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to delete my account"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The privacy policy has been updated, please ", _interpolate(_list(0)), " it before proceeding."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "LOGOUT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters required."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email. If your address is in our database, you'll received an email with a link to reset your password."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password strength"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strong"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalize some, but not all letters."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more words that are less common."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid years that are associated with you."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalize more than the first letter."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid dates and years that are associated with you."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid predictable letter substitutions like ", "@", " for a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use longer keyboard patterns and change typing direction multiple times."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create strong passwords without using symbols, numbers, or uppercase letters."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use this password elsewhere, you should change it."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid recent years."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid repeated words and characters."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid reversed spellings of common words."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid common character sequences."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use multiple words, but avoid common phrases."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weak"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your password have been updated. Click ", _interpolate(_list(0)), " to log in."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account edition"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascent-related data (records, total)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to profile"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date display format"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit preferences"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit sports preferences"])},
    "ELEVATION_CHART_START": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevation chart starts at"])},
      "MIN_ALT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum altitude"])},
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero"])}
    },
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Please ", _interpolate(_list(0)), " to change your email address again or contact the administrator"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First day of week"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture edition"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove picture"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update picture"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences edition"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile edition"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration date"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["color"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled by admin"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["label"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopped speed threshold"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports preferences edition"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been updated successfully. Please check your email to confirm your new email address."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created successfully."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link to activate your account has been emailed to the address provided."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been updated successfully."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apps"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preferences"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sports"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial system (ft, mi, mph, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units for distance"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric system (m, km, m/s, °C)"])}
    },
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered"])},
      "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If filtered, it excludes extreme values (which may be GPS errors) when calculating the maximum speed."])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPX max speed strategy"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw"])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I have read and agree to the ", _interpolate(_list(0)), "."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, registration is disabled."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend account confirmation email"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show password"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user account is inactive."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 30 characters required, only alphanumeric characters and the underscore character \"_\" allowed."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user picture"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You have accepted the ", _interpolate(_list(0)), "."])}
}