export default {
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemmelinghet"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programbeskrivelse"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programnavn"])},
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programnettadresse"])}
  },
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopier til utklippstavlen"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingen programmer"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingen beskrivelse"])}
}