export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen, die für deine Nutzer nützlich sein könnten. Markdown-Syntax wird unterstützt."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Instanz-Informationen"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere Konto"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen/Entfernen von Administratorrechten, Lösche Nutzerkonto."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung"])},
  "APP_CONFIG": {
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt-E-Mail des Administrators"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Dateianzahl im zip Archiv"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn 0, gibt es keine Registrierungslimitierung.."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Anzahl aktiver Nutzer"])},
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Kontakt-E-Mail"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Größe der hochgeladenen Dateien (in Mb)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungskonfiguration"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Größe des zip Archives (in Mb)"])}
  },
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu Admin"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchtest du wirklich das ", _interpolate(_list(0)), " Konto löschen? Alle Daten werden gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden."])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchtest du wirklich das ", _interpolate(_list(0)), " Passwort zurücksetzen?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle E-Mail"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösche Nutzer"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Versand ist deaktiviert."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren/Deaktivieren von Sportarten."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Text eingegeben"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das wasswort wurde zurückgesetzt."])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge deine eigene Datenschutzrichtlinie hinzu oder leer lassen, um die standardmäßige zu verwenden. Markdown-Syntax wird unterstützt."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung ist derzeit deaktiviert."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung ist derzeit aktiviert."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings existieren"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten Administration"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere Anwemdungskonfiguration."])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiere E-Mail"])},
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer"])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adminstatus"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdatum"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountstatus"])},
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsanzahl"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratorrechte hinzufügen"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratorrechte entfernen"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - Benutzer"])}
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail Adresse wurde aktualisiert."])}
}