export default {
  "ERROR": {
    "<time> is missing in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta el elemento <time> en el archivo .gpx."])},
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en la red."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error. Inténtalo de nuevo o contacta con la administración."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por lo menos uno de los ficheros dentro del archivo zip excede el límite de tamaño, comprueba el archivo."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una solicitud de exportación completa."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: debes proporcionar un email válido."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al procesar el fichero gpx."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en el procesado del gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al obtener la configuración."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al guardar el entrenamiento."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al actualizar la configuración"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, inténtalo de nuevo más tarde o contacta con la administración."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, no se permiten nuevos registros."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensión de fichero no permitida."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño del fichero es mayor del permitido."])},
    "gpx file is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo .gpx no es válido."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciales no válidas."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos proporcionados no son válidos."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token no válido, por favor vuelve a iniciar sesión."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token no válido, inicia sesión de nuevo."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nuevo email tiene que ser diferente al email actual"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin fichero."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin fichero seleccionado."])},
    "no tracks in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay pista (<trk>) en el archivo .gpx."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una solicitud de exportación."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña: la contraseña y la confirmación no coinciden."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporciona un auth token válido."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma caducada. Inicia sesión de nuevo."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, pero el nombre de usuario ya está en uso."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe este deporte."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta creada correctamente."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de ficheros en el archivo excede el límite."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe el usuario."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes proporcionar un email válido para el contacto de administración"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes eliminar tu cuenta, ninguna otra tiene permisos de administración."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes permiso."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siguiente"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anterior"])}
  }
}