export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engadir nova aplicación OAuth2"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave secreta"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrición da aplicación"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición en"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noma da aplicación"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de redirección"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ámbitos"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede acceso a escritura na configuración da aplicación."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede acceso de lectura aos puntos de acceso <code>auth</code>."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede acceso de escritura aos puntos de acceso <code>auth</code>."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede acceso de lectura aos puntos de acceso <code>users</code>."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede acceso de escritura aos puntos de acceso <code>users</code>."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de lectura aos puntos de acceso <code>workouts</code>."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permisos de escritura aos puntos de acceso <code>workouts</code>."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL da aplicación"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicacións OAuth2"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creouse correctamente a aplicación. Pon coidado en gardar agora a chave secreta, non se volverá a mostrar."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes certeza de querer eliminar esta app?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A aplicación ", _interpolate(_list(0)), " solicita:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autorizar a ", _interpolate(_list(0)), " a que use a túa conta?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copiar ao portapapeis"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar aplicación"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engadir unha aplicación"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non se atopa a aplicación!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sen aplicacións"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sen descrición"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revogar todas as chaves"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes a certeza de querer eliminar todas as chaves de acceso?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foron revogadas todas as chaves de acceso asociadas."])}
}