export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag"]), _normalize(["Tage"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigt"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation (en)"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versteckt"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aufsteigend"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["absteigend"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortieren"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortiert nach"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Seite"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt"])}
}