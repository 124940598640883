<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 503.162 503.162"
    style="enable-background: new 0 0 503.162 503.162"
    xml:space="preserve"
  >
    <desc id="electricMountainBikingDescription">
      silhouette of a person riding an electric mountain bike
    </desc>
    <g id="g10">
      <g id="g8">
        <path
          d="M149.951,67.997c15.711-7.143,22.739-25.675,15.596-41.416c-7.124-15.701-25.723-22.682-41.453-15.539    c-15.721,7.134-22.702,25.752-15.578,41.444C115.679,68.216,134.23,75.14,149.951,67.997z"
          id="path2"
        />
        <path
          d="M87.517,89.072l-32.828,87.755c-1.979,5.967-1.683,12.594,1.1,18.733c4.055,8.922,12.604,14.525,21.755,15.271    l76.873,6.244l29.137,64.184c4.122,9.046,14.832,13.148,23.906,9.017c9.075-4.131,13.072-14.859,8.951-23.944l-36.424-80.201    c0,0-3.605-13.76-21.343-14.133l-43.873-2.572l21.009-55.166l31.671,20.588c5.584,3.663,10.997,3.682,15.1,1.722l55.051-24.997    c17.069-7.755,6.952-30.036-10.108-22.29l-47.506,21.707l-53.55-34.846c0,0-11.638-8.013-24.241-2.285    C102.205,73.858,91.112,77.243,87.517,89.072z"
          id="path4"
        />
        <path
          d="M423.687,182.488l-2.61,15.042c-2.123,12.154-13.35,25.092-25.092,28.888l-3.711,1.195    c3.041-16.543,1.282-34.148-6.215-50.633c-19.498-42.974-70.094-61.87-112.943-42.419    c-42.878,19.479-61.936,70.017-42.438,112.981c17.069,37.562,57.881,56.744,96.534,47.966l-0.784,1.415    c-5.968,10.796-20.817,19.221-33.144,18.8l-17.892-0.622c-12.336-0.411-30.514,5.002-40.603,12.116l-22.376,15.759    c-10.107,7.104-28.276,12.632-40.612,12.354l-12.001-0.277c12.718-22.845,14.889-51.159,3.242-76.806    c-19.517-42.955-70.074-61.879-113.019-42.381c-42.792,19.44-61.87,70.007-42.372,112.933    c16.667,36.711,56.084,55.788,93.914,48.444l-1.32,2.056c-6.675,10.385-22.08,18.398-34.406,17.92l-32.79-1.291    c-12.326-0.497-24.021,8.97-26.096,21.143l-2.62,15.339c-0.564,3.271-0.354,6.11,0.401,8.501c-0.43,1.778-0.736,3.548-0.736,5.326    v9.562c0,10.557,8.568,19.125,19.125,19.125h460.932c10.557,0,19.115-8.568,19.106-19.125l-0.125-167.507    c0-2.782-0.593-5.221-1.616-7.286c1.396-3.806,2.057-7.841,1.598-11.839l-4.677-40.497c-1.415-12.249-9.763-29.146-18.637-37.724    l-36.127-34.951C434.712,167.418,425.79,170.325,423.687,182.488z"
          id="path6"
        />
      </g>
    </g>
    <g
      id="g8-6"
      transform="matrix(0.61784003,0,0,0.55860964,327.27544,1.4160784)"
    >
      <path
        d="m 49.126,150.126 c 0,-42.346 26.212,-78.68 63.26,-93.643 L 131.29,18.512 C 66.847,27.667 17.126,83.194 17.126,150.126 c 0,60.895 41.157,112.355 97.113,128.035 l 3.636,-32.354 C 77.952,232.337 49.126,194.535 49.126,150.126 Z"
        id="path2-1"
      />
      <path
        d="m 283.019,150.126 c 0,-60.883 -41.139,-112.333 -97.076,-128.025 l -3.718,32.33 c 39.946,13.457 68.794,51.27 68.794,95.695 0,42.099 -25.907,78.255 -62.613,93.379 l -19.428,38.217 c 64.383,-9.207 114.041,-64.707 114.041,-131.596 z"
        id="path4-8"
      />
      <path
        d="m 226.169,134.015 c 1.26,-2.479 1.141,-5.202 -0.314,-7.572 -1.454,-2.371 -4.036,-3.316 -6.818,-3.316 H 158.216 L 171.309,9.3 C 171.755,5.441 169.363,1.443 165.637,0.337 164.868,0.109 164.089,0 163.32,0 c -2.954,0 -5.746,1.681 -7.121,4.442 L 73.946,169.718 c -1.234,2.479 -1.098,5.78 0.36,8.136 1.459,2.355 4.031,4.273 6.802,4.273 h 60.019 L 128.823,291.67 c -0.43,3.844 1.951,7.077 5.657,8.185 0.761,0.228 1.532,0.29 2.293,0.29 2.948,0 5.74,-1.637 7.133,-4.378 z"
        id="path6-7"
      />
    </g>
    <g id="g10-9" transform="translate(226.08362,-24.282003)" />
    <g id="g12-2" transform="translate(226.08362,-24.282003)" />
    <g id="g14-0" transform="translate(226.08362,-24.282003)" />
    <g id="g16-2" transform="translate(226.08362,-24.282003)" />
    <g id="g18-3" transform="translate(226.08362,-24.282003)" />
    <g id="g20-7" transform="translate(226.08362,-24.282003)" />
    <g id="g22-5" transform="translate(226.08362,-24.282003)" />
    <g id="g24-9" transform="translate(226.08362,-24.282003)" />
    <g id="g26-2" transform="translate(226.08362,-24.282003)" />
    <g id="g28-2" transform="translate(226.08362,-24.282003)" />
    <g id="g30-8" transform="translate(226.08362,-24.282003)" />
    <g id="g32-9" transform="translate(226.08362,-24.282003)" />
    <g id="g34-7" transform="translate(226.08362,-24.282003)" />
    <g id="g36-3" transform="translate(226.08362,-24.282003)" />
    <g id="g38-6" transform="translate(226.08362,-24.282003)" />
  </svg>
</template>

<script>
  export default {
    name: 'MountainBikingElectric',
  }
</script>
