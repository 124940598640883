export default {
  "ERROR": {
    "<time> is missing in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<time>-Element fehlt in der .gpx-Datei."])},
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkfehler."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler. Bitte versuche es erneut oder kontaktiere den Administrator."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens eine Datei im ZIP-Archiv überschreitet das Größenlimit, bitte überprüfe das Archiv."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Anfrage zum vollständigen Export existiert bereits."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail: Eine gültige E-Mail muss angegeben werden."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Einlesen der GPX-Datei"])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der GPX-Verarbeitung."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen der Konfiguration."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern des Workouts."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren der Konfiguration"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler. Bitte versuche es erneut oder kontaktiere den Administrator."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler. Die Registrierung ist deaktiviert."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateierweiterung ist nicht erlaubt."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei ist größer als erlaubt."])},
    "gpx file is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die .gpx-Datei ist ungültig."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anmeldedaten."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die bereitgestellten Daten sind ungültig."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Token, bitte erneut anmelden."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Token, bitte erneut anmelden."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die neue E-Mail muss sich von der aktuellen E-Mail unterscheiden"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei angegeben."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei ausgewählt."])},
    "no tracks in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Track (<trk>) in der .gpx-Datei."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Anfrage zum Datenexport existiert bereits."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort: Passwort und Passwortbestätigung stimmen nicht überein."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebe ein gültiges Authentifizierungstoken an."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Signatur ist abgelaufen. Bitte melde dich erneut an."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut mir leid, der Benutzername ist schon vergeben."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportart existiert nicht."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Dateien im Archiv überschreitet die Begrenzung."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer existiert nicht."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Administrator zu kontaktieren, muss eine gültige E-Mail-Adresse angegeben werden"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst Dein Konto nicht löschen, da kein anderer Nutzer hat Administratorrechte besitzt."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine Berechtigung."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhergehende"])}
  }
}