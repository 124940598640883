export default {
  "ERROR": {
    "<time> is missing in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falta o elemento <time> no ficheiro .gpx."])},
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro na rede."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro. Inténtao outra vez ou contacta coa administración."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polo menos un ficheiro no arquivo .zip excede o tamaño máximo, comproba o arquivo."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xa existe unha solicitude de exportación completa."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: debes escribir un correo electrónico válido."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao procesar o ficheiro gpx."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro no procesado gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao obter a configuración."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao gardar o adestramento."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao actualizar a configuración"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro, inténtao outra vez ou contacta coa administración."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro, non se admiten novas usuarias."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non está permitida a extensión do ficheiro."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tamaño do ficheiro supera o tamaño permitido."])},
    "gpx file is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ficheiro .gpx non é válido."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciais incorrectas."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os datos proporcionados non son válidos."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token non válido, accede outra vez."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token non válido, accede outra vez."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O novo email ten que ser diferente ao actual"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen ficheiro proporcionado."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen ficheiro seleccionado."])},
    "no tracks in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ruta (<trk>) no ficheiro .gpx."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xa existe unha solicitude de exportación de datos."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasinal: o contrasinal e a súa confirmación non concordan."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporciona unha chave secreta válida."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caducou a sinatura. Accede outra vez."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentámolo, o nome de usuaria xa está en uso."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non existe o deporte."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta creada correctamente."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de ficheiros no arquivo supera o límite."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non existe a usuaria."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai que proporcionar un correo electrónico válido para contacto de administración"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non podes eliminar a túa conta, non hai outra usuaria con permisos de administración."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non tes permisos."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seguinte"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anterior"])}
  }
}