export default {
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź swój e-mail. Nowa wiadomość z potwierdzeniem została wysłana na podany adres."])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masz już konto?"])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne hasło"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj poprawny adres e-mail."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj hasło"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport danych"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz archiwum"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generowanie linka..."])},
    "STATUS": {
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w trakcie..."])},
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niepowodzenie (proszę zlecić kolejny eksport)"])}
    },
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz poprosić o archiwum w przeciągu 24 godzin"])}
  },
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukryj hasło"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawny token, proszę zlecić nowy reset hasła."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcę usunąć swoje konto"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono użytkowników."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pamiętasz hasła?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrzeba co najmniej ośmiu znaków."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset hasła"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź swój e-mail. Jeśli Twój adres jest w naszej bazie danych, otrzymasz wiadomość z linkiem do resetu hasła."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["średnia"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dobra"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siła hasła"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["silne"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wielkich liter, ale nie samych wielkich."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj jakieś wyrazy, które są mniej popularne."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj lat, które są powiązane z Tobą."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wielkiej litery nie tylko na początku."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj dat, które są powiązane z Tobą."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj przewidywalnych zastępstw liter, jak ", "@", " zamiast a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj dłuższych wzorców na klawiaturze i zmieniaj kierunek pisania wiele razy."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz tworzyć silne hasła bez użycia symboli, liczb czy wielkich liter."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli używasz tego hasła także gdzieś indziej, zmień je."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj ostatnich lat."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj powtarzania słów i znaków."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj pisania wspak popularnych słów."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj popularnych sekwencji znaków."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wielu słów, ale unikaj znanych sformułowań."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["słabe"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Twoje hasło zostało zaktualizowane. Kliknij ", _interpolate(_list(0)), " aby się zalogować."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja konta"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data urodzenia"])},
    "ELEVATION_CHART_START": {
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres wysokości zaczyna się od"])},
      "MIN_ALT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najmniejsza wysokość"])}
    },
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy dzień tygodnia"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniedziałek"])},
    "SPORT": {
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolor"])},
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akcja"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyłączone przez administratora"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktywny"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etykieta"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próg prędkości zatrzymanej"])}
    },
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostało zaktualizowane. Sprawdź swój e-mail aby potwierdzić nowy adres."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostało pomyślnie utworzone."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do aktywacji konta został wysłany e-mailem na podany adres."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostało pomyślnie zaktualizowane."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedziela"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konto"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aplikacje"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zdjęcie"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ustawienia"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polityka prywatności"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sporty"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strefa czasowa"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System imperialny (stopy, mile, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki dystansu"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System metryczny (m, km, m/s, °C)"])}
    },
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane o wzniesieniach (rekordy, podsumowanie)"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O sobie"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj ustawienia"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj ustawienia sportów"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format wyświetlanych dat"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj profil"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do profilu"])},
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Proszę ", _interpolate(_list(0)), " by znów zmienić swój adres e-mail lub skontaktować się z administratorem"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana zdjęcia"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj zdjęcie"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcie"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zdjęcie"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja ustawień"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja profilu"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rejestracji"])},
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja ustawień sportu"])},
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odfiltrowana"])},
      "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy filtrowaniu wartości odstające (które mogą sugerować błąd GPS) nie są brane pod uwagę przy wyliczaniu maksymalnej prędkości."])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustalanie prędkości maksymalnej przy GPX"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oryginalna"])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Przeczytałem(-am) i zaakceptowałem(-am) ", _interpolate(_list(0)), "."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie e-mail potwierdzenia konta"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj swoje hasło"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokaż hasło"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To konto użytkownika jest nieaktywne."])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane od 3 do 30 znaków, można używać tylko liter, cyfr i podkreślenia \"_\"."])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zaakceptowałeś(-aś) ", _interpolate(_list(0)), "."])},
  "ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie konta"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy jesteś pewien, że chcesz usunąć swoje konto? Wszystkie dane zostaną nieodwracalnie skasowane."])},
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie otrzymałeś(-aś) instrukcji?"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj po nazwie użytkownika"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Polityka prywatności uległa zmianie, proszę ", _interpolate(_list(0)), " ją przed przejściem dalej."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja adresu e-mail"])},
  "LOGOUT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno chcesz się wylogować?"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaloguj się"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przejrzyj"])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zdjęcie użytkownika"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestety zakładanie kont jest wyłączone."])}
}