export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["day"]), _normalize(["days"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displayed"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentation"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascending"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descending"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sort"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order by"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par page"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
}