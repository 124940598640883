export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe OAuth2 app toevoegen"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheim"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App omschrijving"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem bij"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de app"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorverwijs URL"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omvang"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geeft toestemming tot aanpassen van de applicatie instellingen."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat leestoegang toe aan <code>auth</code> endpoints."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat schrijftoegang toe aan <code>auth</code> endpoints."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat leestoegang toe aan <code>users</code> endpoints."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat schrijftoegang toe aan <code>users</code> endpoints."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat leestoegang toe aan <code>workouts</code> endpoints."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["staat schrijftoegang toe aan <code>workouts</code> endpoints."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App URL"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth2 apps"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App succesvol aangemaakt. Kopieer zeker de geheime sleutel, deze zal later niet meer verschijnen."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze app wilt verwijderen?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De app ", _interpolate(_list(0)), " vraagt:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Toegang geven aan ", _interpolate(_list(0)), " tot uw gebruikersaccount?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopieer naar klembord"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder app"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een app toe"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App niet gevonden!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen apps"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen beschrijving"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder alle tokens"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u alle tokens wilt intrekken?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bestaande tokens werden ingetrokken."])}
}