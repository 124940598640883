export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend confirmation email"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorize"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my account"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request data export"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
}