export default {
  "ERROR": {
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di rete."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore. Per favore riprova o contatta l'amminstratore."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno un file nell'archivio zip supera il limite di dimensione, per favore controlla."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: dev'essere inserita un'email valida."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella lettura del file gpx."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'elaborazione del file gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella lettura della configurazione."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'aggiornamento della configurazione"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore, per favore riprova o contatta l'amministratore."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore, la registrazione è disabilitata."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File con estensione non permessa."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dimensione del file è maggiore di quella massima permessa."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenziali errate."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati inseriti non sono validi."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token scaduto, per favore ripeti il log in."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token scaduto, per favore ripeti il log in."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nuova email dev'essere diversa dalla vecchia mail"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun file scelto."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun file scelto."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password: la password inserita e la conferma non combaciano."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insersci un token di autenticazione valido."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token scaduto. Per favore ripeti il log in."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi dispiace, quell'username è già esistente."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo sport non esiste."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato con successo."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di files nell'archivio supera il limite massimo permesso."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente non esiste."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dev'essere inserita un'email valida"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi eliminare questo account, nessun'altro account ha permessi di amministratore."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi necessari."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avanti"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precedente"])}
  }
}