export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue OAuth2-Anwendung hinzufügen"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimnis"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungsbeschreibung"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungsname"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitungs-URL"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geltungsbereich"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Schreibzugriff auf die Anwendungskonfiguration"])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Lesezugriff auf <code>auth</code>-Endpunkte"])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Schreibzugriff auf <code>auth</code>-Endpunkte"])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Lesezugriff auf <code>users</code>-Endpunkte"])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Schreibzugriff auf <code>users</code>-Endpunkte"])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Lesezugriff auf <code>workouts</code>-Endpunkte"])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erlaubt Schreibzugriff auf <code>workouts</code>-Endpunkte"])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungs-URL"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth2-Anwendungen"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung erfolgreich erstellt. Stelle sicher, dass du das Geheimnis kopierst, du kannst es nicht erneut anzeigen."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist sicher, dass du diese App löschen willst?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Anwendung ", _interpolate(_list(0)), " fragt an:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " den Zugriff auf deinen Account erlauben?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Zwischenablage kopieren"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung löschen"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung hinzufügen"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung wurde nicht gefunden!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Anwendungen"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Beschreibung"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Tokens zurückrufen"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du alle Tokens zurückrufen möchtest?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vorhandenen und vergebenen Tokens wurde zurückgerufen."])}
}