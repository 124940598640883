export default {
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tøm filtre"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kontoen min"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skru av"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill"])}
}