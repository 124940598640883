export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any additional information that may be useful to your users. Markdown syntax can be used."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed instance information"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add/remove admin rights, delete user account."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
  "APP_CONFIG": {
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator email for contact"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. files of zip archive"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If 0, no limitation on registration."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. number of active users"])},
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no contact email"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. size of uploaded files (in Mb)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application configuration"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. size of zip archive (in Mb)"])}
  },
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to admin"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_list(0)), " account? All data will be deleted, this cannot be undone."])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to reset ", _interpolate(_list(0)), " password?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current email"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sending is disabled."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable/disable sports."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No text entered"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has been reset."])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your own privacy policy or leave blank to use the default one. Markdown syntax can be used."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is currently disabled."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is currently enabled."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workouts exist"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports administration"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update application configuration."])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update email"])},
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["user"]), _normalize(["users"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin status"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration date"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account status"])},
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workout count"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add admin rights"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove admin rights"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - Users"])}
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address has been updated."])}
}