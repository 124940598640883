export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen instructies ontvangen?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw email. Een nieuwe bevestigingsmail werd verzonden naar het opgegeven adres."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al een account aangemaakt?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u uw account wilt verwijderen? Alle data wordt verwijderd, dit kan niet ongedaan worden."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig wachtwoord"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldig email adres op."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een paswoord in"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data exporteren"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archief downloaden"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link aanmaken..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan 1 keer per 24h een archief aanvragen"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fout (gelieve een nieuw archief aan te vragen)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met verwerken..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter op gebruikersnaam"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verberg wachtwoord"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig token, vraag een nieuwe wachtwoord reset aan."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil mijn account verwijderen"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Het privacybeleid werd aangepast, gelieve te ", _interpolate(_list(0)), " voor verdergaan."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inloggen"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenminste 8 karakters vereist."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord reset"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw inbox. Als uw email adres in onze database zit, hebt u een email ontvangen om uw wachtwoord te resetten."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemiddeld"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goed"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord sterkte"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sterk"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik enkele hoofdletters."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg meer niet gangbare woorden toe."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd jaartallen met een persoonlijke betekenis."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik niet enkel voor de eerste letter een hoofdletter."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd persoonlijke gegevens en jaartallen."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd voorspelbare vervangingen, zoals ", "@", " voor de letter a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik langere patronen en verander de richting van typen meerdere malen."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kan sterke wachtwoorden maken zonder gebruik van symbolen, nummers, of hoofdletters."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u dit wachtwoord ergens anders gebruikt zou u het beter veranderen."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd recente jaartallen."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd opeenvolgende woorden en karakters."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd gangbare woorden die achterstevoren geschreven zijn."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermijd voorspelbare opeenvolgingen."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik verschillende woorden, maar vermijd vaak gebruikte zinnen."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zwak"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uw wachtwoord werd aangepast. Klik ", _interpolate(_list(0)), " om in te loggen."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aanpassen"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte gerelateerde data (opnames, totaal)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar profiel"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergaveformaat datum"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel aanpassen"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkeuren wijzigen"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport voorkeuren wijzigen"])},
    "ELEVATION_CHART_START": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte grafiek begint op"])},
      "MIN_ALT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum hoogte"])},
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nul"])}
    },
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gelieve ", _interpolate(_list(0)), " om uw email adres opnieuw te wijzigen of contacteer uw administrator"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste dag van de week"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding aanpassen"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding verwijderen"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding veranderen"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkeuren aanpassen"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel aanpassen"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum registratie"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actie"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleur"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitgeschakeld door admin"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actief"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["label"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["snelheidsgrens voor stilstand"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport voorkeuren aanpassen"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account werd succesvol bijgewerkt. Controleer uw inbox om uw nieuw email adres te bevestigen."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account werd succesvol aangemaakt."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een link om uw account te activeren werd doorgestuurd naar opgegeven email adres."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account werd succesvol bijgewerkt."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apps"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afbeelding"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voorkeuren"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profiel"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sporten"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdzone"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperialistisch systeem (ft, mi, mph, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenheid voor afstand"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrisch systeem (m, km, m/s, °C)"])}
    },
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefilterd"])},
      "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij filteren worden extreme waarden (dewelke mogelijk GPS fouten zijn) niet gebruikt om de maximum snelheid te berekenen."])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategie voor maximale snelheid"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rauw"])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ik heb het ", _interpolate(_list(0)), " gelezen en goedgekeurd."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, registreren is uitgeschakeld."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw zenden van account bevestigingsmail"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset uw wachtwoord"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordeel"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toon wachtwoord"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze account is niet actief."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 tot 30 karakters vereist, enkel alfanumerieke karakters en het underscore karakter \"_\" zijn toegestaan."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersafbeelding"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["U hebt het ", _interpolate(_list(0)), " aanvaard."])},
  "ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account bevestigen"])},
  "LOGOUT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeker dat u wilt uitloggen?"])},
  "EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update email"])}
}