export default {
  "ERROR": {
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość pliku przekracza dozwolony rozmiar."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawny token, proszę się ponownie zalogować."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawny token, proszę się ponownie zalogować."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomyślnie zarejestrowano."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik nie istnieje."])},
    "<time> is missing in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakuje elementu <time> w pliku .gpx."])},
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd sieciowy."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd. Spróbuj ponownie lub skontaktuj się z administratorem."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej jeden plik zip przekracza limit wielkości, sprawdź archiwum."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukończony wniosek eksportu już istnieje."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail: należy podać poprawny adres."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd podczas parsowania pliku gpx."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd podczas procesowania pliku gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd przy pobieraniu konfiguracji."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd przy zapisie treningu."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd przy aktualizacji konfiguracji"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd, spróbuj ponownie lub skontaktuj się z administratorem."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd, rejestracja jest wyłączona."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedozwolone zakończenie pliku."])},
    "gpx file is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik .gpx jest niepoprawny."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawne dane logowania."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekazane dane są niepoprawne."])},
    "new email must be different than current email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy adres e-mail musi być inny niż aktualny"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dostarczono pliku."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wybrano pliku."])},
    "no tracks in gpx file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak trasy (<trk>) w pliku .gpx."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek o eksport danych już istnieje."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło: hasło i powtórzone hasło nie zgadzają się."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostarcz poprawny token."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis wygasł. Proszę się ponownie zalogować."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestety ta nazwa użytkownika jest już zajęta."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taki sport nie istnieje."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba plików w archiwum przekracza limit."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprawny adres e-mail musi być podany do kontaktu z administratorem"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie możesz skasować swojego konta, bo żaden inny użytkownik nie ma praw administratora."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz uprawnień."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["następna"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poprzednia"])}
  }
}