export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-invia email di conferma"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizza"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta filtri"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina il mio account"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])}
}