<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 495.017 495.017"
    style="enable-background: new 0 0 495.017 495.017"
    xml:space="preserve"
  >
    <desc id="mountaineeringDescription">
      silhouette of a person doing mountaineering
    </desc>
    <g>
      <path
        d="M271.32,78.354c21.633,0,39.192-17.54,39.192-39.182C310.513,17.541,292.953,0,271.32,0
        c-21.633,0-39.175,17.541-39.175,39.173C232.146,60.814,249.688,78.354,271.32,78.354z"
      />
      <path
        d="M312.763,349.373l-47.021-43.163l2.087-15.884l-70.055-4.301c-4.173,6.412-9.963,14.482-17.193,23.104l-19.17,72.416
        l-54.928,73.797c-8.185,11.007-5.92,26.567,5.095,34.76c4.449,3.314,9.658,4.916,14.817,4.916c7.583,0,15.058-3.461,19.941-10.011
        l57.794-77.641c1.893-2.539,3.284-5.417,4.092-8.475l16.625-62.781l45.518,41.786l-6.211,90.579
        c-0.938,13.691,9.381,25.548,23.083,26.485c0.565,0.041,1.146,0.057,1.73,0.057c12.938,0,23.859-10.035,24.748-23.146
        l7.036-102.498C321.269,361.843,318.325,354.477,312.763,349.373z"
      />
      <path
        d="M398.667,183.428h-70.005l-39.482-56.053l0.13-0.956c1.405-10.708-1.506-21.537-8.091-30.101
        c-6.581-8.564-16.288-14.16-26.994-15.567c-11.858-1.545-23.1,2.271-31.54,9.429V43.548c0-8.022-6.503-14.532-14.542-14.532
        h-40.277c-8.038,0-14.542,6.51-14.542,14.532v22.637h-12.033c-8.586,0-16.628,4.255-21.463,11.363
        c-4.836,7.108-5.838,16.149-2.684,24.149l36.18,91.856v22.718c0,11.152,4.58,21.125,11.876,28.427
        c5.046-6.082,12.743-9.713,20.526-9.195l88.571,5.443l6.938-52.94l19.767,28.047c3.881,5.507,10.19,8.783,16.921,8.783h80.744
        c11.437,0,20.701-9.268,20.701-20.702C419.367,192.696,410.104,183.428,398.667,183.428z"
      />
      <path
        d="M191.093,273.166l82.25,5.055c7.117,0.55,12.748-4.789,13.166-11.64c0.422-6.849-4.786-12.737-11.643-13.157l-89.903-5.523
        c-5.015-0.348-9.573,2.352-11.805,6.737c-2.039,3.97-50.771,96.822-143.537,83.768c-6.662-0.906-13.068,3.777-14.023,10.569
        c-0.954,6.794,3.768,13.078,10.563,14.032c7.198,1.011,14.169,1.488,20.895,1.488C128.224,364.487,177.119,295.915,191.093,273.166
        z"
      />
      <path
        d="M476.659,251.313c-14.223-9.793-36.401-18.891-66.555-18.488c-35.468,0.473-68.598,27.748-69.198,28.412
        c-6.13,6.835-5.562,17.348,1.278,23.478c6.824,6.139,17.339,5.564,23.469-1.268l0.114-0.13c0,0,19.495-20.418,36.814-24.925
        l-5.822,215.844c0,0.307,0,0.678,0,0.994c0.289,10.198,8.765,18.236,18.955,17.963c10.206-0.276,18.245-8.759,17.985-18.957
        l-5.951-221.215c27.271,0.444,43.739,8.938,43.739,8.938c2.65,1.035,5.741,0.022,7.242-2.501
        C480.414,256.635,479.359,253.172,476.659,251.313z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Mountaineering',
  }
</script>
