export default {
  "ACCOUNT-CONFIRMATION-RESEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar correo de confirmación"])},
  "AUTHORIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizar"])},
  "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "CLEAR_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtros"])},
  "DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar mi cuenta"])},
  "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
  "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "ENABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
  "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "REQUEST_DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar exportación de datos"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])}
}