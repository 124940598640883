export default {
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["dzień"]), _normalize(["dni"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlony"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dokumentacja"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryty"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rosnąco"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["malejąco"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortuj"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uporządkuj według"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na stronę"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])}
}