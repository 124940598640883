export default {
  "ADD_A_NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir aplicación OAuth2"])},
  "APP": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "CLIENT_SECRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave secreta"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la aplicación"])},
    "ISSUE_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición en"])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la aplicación"])},
    "REDIRECT_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de redirección"])},
    "SCOPE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ámbito"])},
      "application:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de escritura a la aplicación configurada."])},
      "profile:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de lectura a puntos de acceso <code>auth</code>."])},
      "profile:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de lectura a los puntos de acceso <code>auth</code>."])},
      "users:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de lectura a los puntos de acceso <code>users</code>."])},
      "users:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de escritura a los puntos de acceso <code>users</code>."])},
      "workouts:read_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de lectura a los puntos de acceso <code>workouts</code>."])},
      "workouts:write_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concede permiso de escritura a los puntos de acceso <code>workouts</code>."])}
    },
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de la aplicación"])}
  },
  "APPS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicaciones OAuth2"])},
  "APP_CREATED_SUCCESSFULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación creada correctamente. Asegúrate de que copias la clave secreta, no volverá a ser mostrada."])},
  "APP_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar esta app?"])},
  "APP_REQUESTING_ACCESS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La aplicación ", _interpolate(_list(0)), " solicita:"])},
  "AUTHORIZE_APP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Autorizar a ", _interpolate(_list(0)), " a usar tu cuenta?"])},
  "COPY_TO_CLIPBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copiar al portapapeles"])},
  "DELETE_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar aplicación"])},
  "NEW_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una aplicación"])},
  "NO_APP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encuentra la aplicación!"])},
  "NO_APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin aplicaciones"])},
  "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin descripción"])},
  "REVOKE_ALL_TOKENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revocar todas las claves"])},
  "TOKENS_REVOCATION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de revocar todas las claves?"])},
  "TOKENS_REVOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las claves asociadas han sido revocadas."])}
}