export default {
  "Cycling (Sport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radfahren (Sport)"])}
  },
  "Cycling (Transport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radfahren (Pendeln)"])}
  },
  "Cycling (Virtual)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radfahren (Virtuell)"])}
  },
  "Hiking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wandern"])}
  },
  "Mountain Biking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mountainbiken"])}
  },
  "Mountain Biking (Electric)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mountainbiken (elektrisch)"])}
  },
  "Mountaineering": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergsteigen"])}
  },
  "Rowing": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rudern"])}
  },
  "Running": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufen"])}
  },
  "Skiing (Alpine)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skifahren (Alpin)"])}
  },
  "Skiing (Cross Country)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skifahren (Langlauf)"])}
  },
  "Snowshoes": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneeschuhe"])}
  },
  "Trail": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trail"])}
  },
  "Walking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen"])}
  },
  "Open Water Swimming": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offenes Schwimmen im Wasser"])}
  },
  "Paragliding": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragliden"])}
  }
}