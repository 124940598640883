export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acerca de"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacto"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["día"]), _normalize(["días"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrado"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentación"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oculto"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascenso"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descenso"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordenar"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordenar por"])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por página"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
}