export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottok du ikke noen instruks?"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du allerede en konto?"])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåværende passord"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn en gyldig e-postadresse."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn et passord"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataeksport"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned arkiv"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["genererer lenke …"])},
    "STATUS": {
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["underveis …"])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer på brukernavn"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skjul passord"])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vil slette kontoen min"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logg inn"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt passord"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen brukere."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glemt passordet?"])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gjen."])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bra"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passordstyrke"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sterkt"])},
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svakt"])}
  },
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoredigering"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til profil"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsdag"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger profil"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger innstillinger"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandag"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilde"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bilde"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater bilde"])},
    "TABS": {
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidssone"])},
    "USE_RAW_GPX_SPEED": {
      "FILTERED_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrert"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks hastighet strategi"])},
      "RAW_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rå"])}
    }
  },
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passordet ditt"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vis passord"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne brukerkontoen er inaktiv."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernavn"])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brukerbilde"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har samtykket til ", _interpolate(_list(0)), "."])}
}