export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calquera información adicional que poida ser útil para as túas usuarias. Pódese usar a sintaxe de Markdown."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información detallada da instancia"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar conta"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engadir/eliminar dereitos de administración, eliminar a conta da usuaria."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación"])},
  "APP_CONFIG": {
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contacto da administración"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num. máximo ficheiros con arquivos zip"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se 0, non hai límite de rexistros."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número máx. de usuarias activas"])},
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sen correo de contacto"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño Máx. dos ficheiros subidos (en MB)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración da aplicación"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño Máx. do arquivo zip (en MB)"])}
  },
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a admin"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tes a certeza de querer eliminar a conta ", _interpolate(_list(0)), "? Borraranse todos os datos, non hai volta atrás."])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tes certeza de querer restablecer o contrasinal de ", _interpolate(_list(0)), "?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email actual"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar usuaria"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O envío de correos está desactivado."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar/desactivar deportes."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo email"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non escribiches texto"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restableceuse o contrasinal."])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engade a túa propia política de privacidade ou déixaa baleira para usar unha por defecto. Podes usar Markdown."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente non se poden crear máis contas."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente pódense crear novas contas."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contrasinal"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xa existe o adestramento"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imaxe"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar deportes"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar configuración da aplicación."])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar email"])},
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["usuaria"]), _normalize(["usuarias"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rol de admin"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data de rexistro"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estado da conta"])},
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificador"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número adestramentos"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceder permisos admin"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar permisos admin"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración - Usuarias"])}
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizouse o enderezo de email."])}
}