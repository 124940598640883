export default {
  "ERROR": {
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettverksfeil."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke lagre arbeidsøkt."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filendelsen tillates ikke."])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fil angitt."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen valgt fil."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporten finnes ikke."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukeren finnes ikke."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neste"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forrige"])}
  }
}